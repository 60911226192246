<template>
  <div>
    <!-- Modal overlay -->
    <div class="modal-overlay" :class="{ active: showModal }">
        <!-- Modal content -->
        <div class="modal-content py-3">
          <div class="container pt-3">
            <button
              class="float-right"
              style="bachground-color: transparent; border: none"
              @click="closeModal"
            >
              <i class="bi bi-x" style="font-size: 20px"></i>
            </button>
            <div class="row">
              <div class="col-md-5">
                <img
                  :src="
                    'https://backend.seid.in/storage/product/' +
                    selectedMeal.image
                  "
                  alt=""
                  class="img-fluid pb-3"
                />
              </div>
              <div class="col-md-7">
                <h2 style="font-size: 19px">{{ selectedMeal.name }}</h2>
                <p>{{ selectedMeal.category && selectedMeal.category.name }}</p>
                <div class="row">
                  <div class="col-md-5 col-12">
                    <p style="font-size: 17px; color: #e23744">
                      ₦ {{ (checkItemInCart(selectedMeal.id) ? checkItemQuantity(selectedMeal.id) : 1) * selectedMeal.price }}
                    </p>
                  </div>
                  <!-- <div class="col-md-6 col-6">
                    <h2 style="font-size: 19px">Quantity</h2>
                  </div> -->
                  <div class="col-md-7 col-12">
                    <div>
                      <div class="float-right">
                        <button :disabled="checkItemQuantity(selectedMeal.id) === 0" class="dec-button mr-3" @click="minus(selectedMeal.id)">
                          -
                        </button>
                        {{ checkItemInCart(selectedMeal.id) ? checkItemQuantity(selectedMeal.id) : quantity }}
                        <button class="dec-button ml-3" @click="add(selectedMeal,selectedMeal.id)">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h2 style="font-size: 19px" class="pt-3">Description</h2>
                <p>
                  {{ selectedMeal.description }}
                </p>
              </div>

              <div
                class="col-md-12"
                v-if="selectedMeal.add_ons && selectedMeal.add_ons.length"
              >
                <h2 style="font-size: 19px" class="pt-3">
                  Extra <span style="color: #e23744">(Optional)</span>
                </h2>
              </div>
              <div
                class="col-md-12 mb-2"
                v-for="(s, i) in selectedMeal.add_ons"
                :key="i"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            type="checkbox"
                            name=""
                            class="form-control"
                            :value="checkCheckedID(s.id)"
                            :checked="checkChecked(s.id)"
                            @change="addToSelectedAddons(s,selectedMeal.id)"
                            style="max-width: 15px;cursor:pointer;"
                          />
                          <label class="pt-2 pl-2" :for="s.name">{{
                            s.name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <p class="text-gray mb-3 time">
                          <span
                            v-if="s.quantity"
                            class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 float-right"
                            >₦ {{ s.price * s.quantity }}</span
                          >
                          <span
                            v-else
                            class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 float-right"
                            >₦ {{ s.price }}</span
                          >
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <div class="float-right">
                            <button :disabled="checkAddonQuantity(s.id,selectedMeal.id) === 1 || !checkSelectedAddon(s.id,selectedMeal.id)" class="dec-button mr-3" @click="minusAd(s, selectedMeal.id)">
                              -
                            </button>
                               {{ checkSelectedAddon(s.id,selectedMeal.id) ? checkAddonQuantity(s.id,selectedMeal.id) : 1 }}
                            <button :disabled="!checkSelectedAddon(s.id,selectedMeal.id)" class="dec-button ml-3" @click="addAd(s,selectedMeal.id)">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary mt-3 mb-3"
            @click="addToCart(selectedMeal)"
            :disabled="checkItemInCart(selectedMeal.id)"
          >
            {{checkItemInCart(selectedMeal.id) ? "Already In Cart" : "Add To Cart"}}
          </button>
        </div>
      </div>
    <div class="osahan-trending">
      <div class="container">
        <div class="most_popular py-5">
          <div class="d-flex align-items-center mb-4">
            <h3 class="fw-bold text-dark mb-0">Trending Meals</h3>
            <!-- <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#filters"
              class="ms-auto btn btn-primary"
              >Filters</a
            > -->
          </div>
          <div class="row g-4 mb-4">
            <div class="col-md-3 mb-3" v-for="(l, i) in latestMeals" :key="i">
              <div class="osahan-slider-item">
                <div
                  class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
                >
                  <div class="list-card-image">
                    <div class="star position-absolute">
                      <span class="badge badge-success"
                        ><i class="feather-star"></i> 3.1 (300+)</span
                      >
                    </div>
                    <div class="favourite-heart text-danger position-absolute">
                      <a href="#"><i class="feather-heart"></i></a>
                    </div>
                    <div class="member-plan position-absolute">
                      <span class="badge badge-dark"
                        >{{ l.restaurant_name }} |
                        {{
                          l.min_delivery_time + "|" + l.max_delivery_time
                        }}min</span
                      >
                    </div>
                    <div @click="openModal(l)" style="cursor: pointer">
                      <img
                        alt="#"
                        :src="
                          'https://backend.seid.in/storage/product/' + l.image
                        "
                        width="100%"
                      />
                    </div>
                  </div>
                  <div class="p-3 position-relative">
                    <div class="list-card-body">
                      <h6 class="mb-1">
                        <div @click="openModal(l)" class="text-black">
                          {{ l.name }}
                        </div>
                      </h6>
                      <p class="text-gray mb-3">
                        {{ l.category && l.category.name }}
                      </p>
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <p class="text-gray mb-3 time">
                            <span
                              class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"
                              ><i class=""></i>₦ {{ l.price }}</span
                            >
                          </p>
                        </div>
                        <div class="col-md-6 col-6">
                          <button
                            class="btn btn-primary float-right"
                            @click="openModal(l)"
                          >
                            <i
                              class="feather-shopping-cart"
                              style="text-style: normal; font-size: 14px"
                            >
                              Order</i
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Styles for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-overlay.active {
  display: flex;
}

/* Styles for the modal content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 500px;
  border-radius: 10px;
  overflow-y: auto;
}
.dec-button {
  background-color: #e23744;
  border: 1px solid #e23744;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dec-button:disabled {
  opacity: 0.5;
}

/* Responsive styles */
@media (max-width: 600px) {
  .modal-content {
    max-width: 80%;
    height: 70%;
    scroll-behavior: smooth;
  }
}
</style>
<script>
import { Carousel, Slide } from "vue-carousel";
import { EventBus } from "@/eventBus.js";
import swal from "sweetalert";
export default {
  data() {
    return {
      showModal: false,
      quantity: 0,
      selectedMeal: {},
      foods: {},
      cartItems: {},
      categories: {},
      latestMeals: {},
      zones: {},
      selectedZone: null,
      banners: [],
      addon_quantity: 1,
      selectedAddons: [],
      selectedAddons: [],
    };
  },
  components: {
    Carousel,
    Slide,
    swal
  },
   watch:{
    cartItems(newValue){
      this.checkItemQuantity(this.selectedMeal.id)
      this.checkItemInCart(this.selectedMeal.id)
    }
   
  },
  methods: {
    openModal(meal) {
      this.selectedMeal = meal;
      console.log(meal)
      // cheeck from cart the addon with the selected meal addon 
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant = cartItems.some((item) => item.product.id === meal.id)
      if(checkRestaurant){
          let addon = cartItems.find((item) => item.product.id === meal.id).addons
          console.log(addon)
          this.cartAddons = addon
          this.selectedAddons = addon
      }
      else{
        this.cartAddons = []
        this.selectedAddons = []
      }
      
      this.showModal = true;
    },
    checkCheckedID(id){
       let idExist = this.cartAddons.some((item) => item.id === id)
       let checkedID;
       if(idExist){
          let checkID = this.cartAddons.find((item) => item.id === id).id
          checkedID = checkID
       }
       else{
        checkedID = id
       }
       return checkedID
    },
    checkChecked(id){
      let idExist = this.cartAddons.some((item) => item.id === id)
       let checkedID;
       if(idExist){
          checkedID = true
       }
       else{
        checkedID = false
       }
       return checkedID
    },
      handleEnterKey(){
      this.$router.push(`/search?query=${this.searchQuery}`)
    },
    closeModal() {
      this.showModal = false;
    },
     checkItemInCart(id){
      let res = this.cartItems.some((item) => item.product.id === id)
      return res
    },
   checkItemQuantity(id) {
      let qty;
      // let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let res = this.cartItems.some((item) => item.product.id === id);
      if(res){
        let qtyVal = this.cartItems.find((item) => item.product.id === id);
        qty = qtyVal.quantity
      }
      else{
        qty = this.quantity
      }
      return qty
    },
    add(product,id) {
     let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant =  cartItems.some((item) => item.product.restaurant_id !== product.restaurant_id)
      //  check for restarurant if it's in cart
       if (checkRestaurant) {
        this.$toast.error(
          "You cannot add food from different restaurants to the cart",
          {
            // Add your preferred toast configuration for displaying an error message
          }
      )
    }
      else{
     const cartItem = cartItems.find(item => item.product.id === id);
    //  if item exist in cart, add quantity else push to cart a new item from same restaurant
      if (cartItem) {
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            quantity: item.quantity + 1
          } : item)
          
       localStorage.setItem("cartItems", JSON.stringify(newCartItems));
       this.cartItems = newCartItems
       this.$toast.success(
          "Item quantity updated",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
      } else {      
      // Add the new item to the cart
        cartItems.push({
          quantity: 1,
          product: product,
          addons: this.selectedAddons,
          addon_quantity: this.addon_quantity,
        });

      //  this.closeModal();
            this.$toast.success(
          "Item added to cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        )
  
        // Update the localStorage with the updated cart items
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
         this.cartItems = cartItems
          this.closeModal();
      }
      }

     
     
    },
    minus(id) {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const cartItem = cartItems.find(item => item.product.id === id);
      if (cartItem) {
        if(cartItem.quantity === 1){
        let removedItem = cartItems.filter(item => item.product.id !== id)
        localStorage.setItem("cartItems", JSON.stringify(removedItem));
        this.cartItems = removedItem
          this.$toast.success(
            "Item removed from cart",
            {
              position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            }
          );
        }
        else{
       let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            quantity: item.quantity - 1
          } : item)

       localStorage.setItem("cartItems", JSON.stringify(newCartItems));
        this.cartItems = newCartItems
        this.$toast.success(
          "Item quantity updated",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
        }
      
      } 
    },

      addAd(s, id) {
      
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingAddonIndex = this.selectedAddons.findIndex(
        (item) => item.addon === s.name
      );

      if (existingAddonIndex !== -1) {
        // If the addon already exists, update its quantity
        this.selectedAddons[existingAddonIndex].quantity++;
        this.selectedAddons[existingAddonIndex].selected = true; // Mark the addon as selected

        // update the cart with this addon, check cartitems for exact cart and add this addon change to it
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            addons: this.selectedAddons
          } : item)
        this.cartItems = newCartItems
        localStorage.setItem("cartItems", JSON.stringify(newCartItems));

          this.$toast.success(
          "Top on updated in cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
      }


      this.closeModal();
      this.showModal = true;
    },
    checkAddonQuantity(id, product_id) {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant = cartItems.some((item) => item.product.id === product_id)
      let qty;
      //  check product in cart, get the addon and compare with the addon id to get qty
      if(checkRestaurant){
        let addon = cartItems.find((item) => item.product.id === product_id).addons
          // let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
          let res = addon.some((item) => item.id === id);
          if(res){
            let qtyVal = addon.find((item) => item.id === id);
            console.log(qtyVal)
            qty = qtyVal.quantity
          }
          else{
            qty = 1
          }
      }
      else{
        qty = 1
      }
      console.log(qty)
     return qty
    
    },
   
    checkSelectedAddon(id, product_id){
      let res = this.cartAddons.some((item) => item.id === id)
      console.log(res)
      return res
    },
     addToSelectedAddons(s, id) { 
       // item must be in cart before addons can be added;
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        let checkRestaurant =  cartItems.some((item) => item.product.id === id)
        if(checkRestaurant){
             // check if addon is checked
                  // Check if the addon is checked push addon as a new addon with quantity 1 else remove it if unchecked entirely
                let existingAddonIndex =  this.selectedAddons.some((item) => item.id === s.id)

                if (existingAddonIndex) {
                  // If the addon already exists, update its quantity
                  let removedAddon =  this.selectedAddons.filter(item => item.id !== s.id)
                  this.selectedAddons = removedAddon
                  this.cartAddons = removedAddon

                  // update the cart with this addon, check cartitems for exact cart and add this addon change to it
                    let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
                      {
                        ...item, 
                        addons: this.selectedAddons
                      } : item)
                    this.cartItems = newCartItems
                  localStorage.setItem("cartItems", JSON.stringify(newCartItems));

                } else {
                  this.selectedAddons.push({
                    id: s.id,
                    addon: s.name,
                    quantity: 1,
                    selected: true,
                    price: s.price,
                  });

                   this.cartAddons.push({
                    id: s.id,
                    addon: s.name,
                    quantity: 1,
                    selected: true,
                    price: s.price,
                  });
                  
                  // update the cart with this addon
                   let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
                      {
                        ...item, 
                        addons: this.selectedAddons
                      } : item)
                    this.cartItems = newCartItems
                  localStorage.setItem("cartItems", JSON.stringify(newCartItems));
                  // this.selectedAddons = newAddons
              }
        }
        else{
          this.$toast.error(
            "Kindly add the product to cart before adding an addon!",
            {
              // Add your preferred toast configuration for displaying an error message
            }
          )
        }
      
     
      this.closeModal();
      this.showModal = true;
      console.log(this.selectedAddons)
    },
     minusAd(s,id) {
        // If the quantity becomes 0, remove the addon from selectedAddons
         let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const addonIndex = this.selectedAddons.findIndex(
          (item) => item.addon === s.name
        );
        if (addonIndex !== -1) {
          this.selectedAddons[addonIndex].quantity--;

            // update the cart with this addon, check cartitems for exact cart and add this addon change to it
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            addons: this.selectedAddons
          } : item)
          this.cartItems = newCartItems
          localStorage.setItem("cartItems", JSON.stringify(newCartItems));

          this.$toast.success(
          "Top on updated in cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
        }
      
      this.closeModal();
      this.showModal = true;
       console.log(this.selectedAddons)
    },
    getLatestMeals() {
      this.$api
        .get(this.baseUrl("products/latest?type=all&limit=0&offset=0"))
        .then((res) => {
          this.latestMeals = res.data.products;
          this.showPage = true;
        });
    },
    getRecomendedMeals() {
      this.$api
        .get(this.baseUrl("products/recommended?type=all&limit=0&offset=0"))
        .then((res) => {
          this.recomendedMeals = res.data.products;
          this.showPage = true;
        });
    },
    getCategories() {
      this.$api.get(this.baseUrl("categories")).then((res) => {
        this.categories = res.data;
        this.showPage = true;
      });
    },
    pickZone(zone) {
      localStorage.setItem("zone", JSON.stringify(zone));
      const zoneValue = JSON.parse(localStorage.getItem("zone"));
      this.selectedZone = zoneValue;
    },
    getZones() {
      this.$api
        .get(this.baseUrl("zone/list"))
        .then((res) => {
          // Assuming res.data is an array of zones
          if (Array.isArray(res.data) && res.data.length > 0) {
            this.zones = res.data;
            this.selectedZone = res.data[0];

            // Get the value from the zone storage
            const zoneValue = JSON.parse(localStorage.getItem("zone"));

            // If the value is empty (null or undefined), set the value in the zone storage
            if (!zoneValue) {
              this.selectedZone = res.data[0];
              localStorage.setItem("zone", JSON.stringify(this.selectedZone));
            } else {
              this.selectedZone = zoneValue;
            }
          } else {
            console.error("Invalid response data for zones:", res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching zones:", error);
          // You might want to handle the error or show a message to the user.
        });
    },
    getBanners() {
      this.$api.get(this.baseUrl("banners")).then((res) => {
        this.banners = res.data.banners;
        this.showPage = true;
      });
    },
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    addToCart(product) {
      // Retrieve the existing cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

      // Check if the product being added has the restaurant_id property
      if (!product.restaurant_id) {
        this.$toast.error("Invalid product: missing restaurant_id", {
          // Add your preferred toast configuration for displaying an error message
        });
        return;
      }

      // Check if any product in the cart already has a different restaurant_id
      if (
        cartItems.some(
          (item) => item.product.restaurant_id !== product.restaurant_id
        )
      ) {
        this.$toast.error(
          "You cannot add food from different restaurants to the cart",
          {
            // Add your preferred toast configuration for displaying an error message
          }
        );
        return;
      }

      // Add the new item to the cart
      cartItems.push({
        quantity: 1,
        product: product,
        addons: this.selectedAddons,
        addon_quantity: this.addon_quantity,
      });

      swal({
        title: "Item Added to cart",
        // text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "success",
        buttons: ["Continue Shopping", "Checkout"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$router.push("/cart");
        } else {
        }
      });

      // Update the localStorage with the updated cart items
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      this.cartItems = cartItems
      // Rest of your code...
      EventBus.$emit("itemAddedToCart");
      // this.closeModal();
    },
  },
  mounted() {
    this.getLatestMeals();
    this.displayCart();
    // End of use strict
  },
};
</script>
<template>
  <div>
    <div class="py-4 osahan-main-body">
      <div class="container">
        <div class="row">
          <div class="col-md-4 d-none d-md-block">
            <ProfileSidebar />
          </div>
          <div class="tab-content col-md-8" id="subscription">
            <h5 class="mb-3 d-md-none block">My Subscription Plans</h5>
            <div v-if="loading">
             <div class="">
                 <div
                      v-for="n in 4"
                      class="tab-pane fade show active"
                      :key="n"
                >
                  <div class="p-3 rounded shadow-sm bg-white mb-3" >
                     <div style="display: flex; gap:10px; align-items: center;">
                      <div>
                          <VueSkeletonLoader
                          type="circle"
                          rounded
                          :width="74"
                          :height="74"
                          animation="fade"
                        /> 
                      </div>
                      <div>
                        <VueSkeletonLoader
                            type="rect"
                            rounded
                              :width="600"
                            :height="10"
                            animation="fade"
                            class="loader-rect"
                            />
                            <VueSkeletonLoader
                            type="rect"
                            rounded
                            :width="600"
                            :height="10"
                            animation="fade"
                               class="loader-rect mt-2"
                            />
                              <VueSkeletonLoader
                            type="rect"
                            rounded
                            :height="10"
                              :width="600"
                            animation="fade"
                            class="mt-2 loader-rect"
                            />
                      </div>
                     </div>
                     <div class="d-flex border-bottom pb-3"></div>
                       <VueSkeletonLoader
                            type="rect"
                            rounded
                            :height="10"
                              :width="600"
                            animation="fade"
                            class="mt-3 loader-rect"
                          />
                  </div>

                  
               
                </div>
             </div>     
         </div>

            <div
              class="tab-pane fade show active"
              id="orders"
              role="tabpanel"
              aria-labelledby="completed-tab"
              v-for="(or, i) in subplans"
              :key="i"
              v-else
            >
              <div class="order-body">
                <div class="pb-3">
                  <div class="p-3 rounded shadow-sm bg-white">
                    <div class="d-flex border-bottom pb-3">
                      <div class="text-muted mr-3">
                        <img
                          alt="#"
                          :src="
                            'https://backend.seid.in/storage/product/2023-07-08-64a9363aa07d8.png' 
                          "
                          class="img-fluid order_img"
                        />
                      </div>
                      <div>
                        <p class="mb-1 fw-bold">
                          <a href="restaurant.html" class="text-dark font-weight-bold"
                            >{{or.subscription_plan?.name}} Plan</a
                          >
                        </p>
                        <p class="mb-0">{{or.subscription_plan?.description}}</p>
                        <p class="mb-1">ORDER REF #{{ or?.reference }}</p>
                        <p class="mb-1"><span class="font-weight-bold">Start Date:</span> {{ or?.start_date }}</p>
                        <p class="mb-1"><span class="font-weight-bold">Expiry Date:</span> {{ or.subscription_plan?.expiry_date }}</p>
                        <p class="mb-1"><span class="font-weight-bold">Duration:</span> {{ or.subscription_plan?.duration }} days</p>
                        <p class="mb-1"><span class="font-weight-bold">Addtional Note:</span> {{ or.note }}</p>
                        <p class="mb-0"><span class="font-weight-bold">Address:</span> {{ or.address }}</p>
                      

                        <!-- <p class="mb-0 small">
                          <router-link :to="`/order-details?ref=${or.id}`">View Details</router-link>
                        </p> -->
                        
                      </div>
                      <div class="ml-auto">
                          <p
                              v-if="or.status !== 'PAID'"
                              class="bg-warning text-white py-1 px-2 rounded small text-center mb-1"
                            >
                              {{ capitalizeFirstLetter(or.status) }}
                            </p>
                            <p
                              v-else
                              class="bg-success text-white py-1 px-2 rounded small text-center mb-1"
                            >
                              {{ or.status }}
                            </p>
                       
                           <p class="small fw-bold text-center">
                              <i class="feather-clock"></i>
                              {{ formatDate(or.created_at) }}
                            </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center pt-3">
                      <div class="small">
                      <p class="text- fw-bold mb-0"></p>
                      </div>
                      <div class="text-muted m-0 ml-auto mr-3 small">Total Payment<br>
                      <span class="text-dark fw-bold">₦{{or.amount}}</span>
                      </div>
                      <!-- <div class="text-end">
                        <button
                        class="btn btn-primary"
                        v-if="or.payment_status === 'unpaid'"
                        @click="payWithPaystackMultiple(or)"
                      >
                        Verify Transaction
                      </button>
                      </div> -->
                    </div>
                
                  </div>
                </div>
              </div>
            </div>

          <div v-if="!loading && subplans.length === 0" style="margin-top: 60px;">
              <div class="row d-flex align-items-center justify-content-center py-8 mt-10">
                <div class="col-md-4 py-8">
                 <div class="text-center py-5">
                    <p class="h4 mb-4"><i class="feather-search bg-primary text-white rounded p-2"></i></p>
                    <p class="fw-bold text-dark h5">Nothing found</p>
                    <p>You don't have any active subscription yet!</p>
                </div>
                </div>
            </div>
         </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileSidebar from "../Menu/ProfileSidebar.vue";
import axios from "axios";
import VueSkeletonLoader from 'skeleton-loader-vue';
export default {
  data() {
    return {
      subplans: [],
      user: null,
      partOrder: {},
      responseBod: {},
      loading: false
    };
  },
  components: {
    ProfileSidebar,
    axios,
    VueSkeletonLoader
  },
  methods: {
    getSubPlans() {
      this.loading = true
      this.$store.dispatch("getUserSubPlans")
        .then((res) => {
          console.log(res)
          this.loading = false
          this.subplans = res.suscriptionPlans;
        })
        .catch(()=>{
           this.loading = false
        });
    },
     capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
    },

    testPaymentVerify() {
      //   alert(1);
      this.$api
        .post(this.baseUrl("test-payment-verify"), this.responseBod)
        .then((res) => {
          console.log(res);
        });
    },
    generateRandomText(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomText = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomText += characters.charAt(randomIndex);
      }

      return randomText;
    },
   payWithPaystack(order) {

      this.partOrder = order;
      var handler = PaystackPop.setup({
        key: "pk_test_be5fdcee5bc309d31327da3389210351795d3ea7", // Replace with your public key

        email: this.user.email,

        amount: order.order_amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit

        currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars

        ref: order.id + this.generateRandomText(5), // Replace with a reference you generated

        callback: (response) =>{
          //this happens after the payment is completed successfully
          //   console.log(this);
          var reference = response.reference;
      
          let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
          let payload = {
            order_id: order.id,
            transaction_reference: response.reference,
          };
          axios
            .post(
              "https://backend.seid.in/api/v1/test-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {  
                setTimeout(() => {
                this.$router.push(`/order-successful?trxref=${reference}`);
              }, 500);      
            })
            .catch(()=>{
             this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
              console.log('an error occured')
            })

          // Make an AJAX call to your server with the reference to verify the transaction
        },


        onClose: function () {
          alert("Transaction was not completed, window closed.");
        },
      });
      handler.openIframe();
    },

     payWithPaystackMultiple(order_id, amount) {
      var handler = PaystackPop.setup({
        key: "pk_test_be5fdcee5bc309d31327da3389210351795d3ea7", // Replace with your public key

        email: this.user.email,

        amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit

        currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars

        ref: order_id + this.generateRandomText(5), // Replace with a reference you generated

        callback: (response) => {
          localStorage.removeItem("cartItems");
          localStorage.setItem("discountAmount", 0);
          var reference = response.reference;

          let payload = {
            sub_id: order_id,
            transaction_reference: response.reference,
          };
          
          // Make an AJAX call to your server with the reference to verify the transaction
           let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
          axios
            .post(
              "https://backend.seid.in/api/v1/test-multiple-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
            })
             .catch(()=>{
               this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
              console.log('an error occured')
            });
        },

        onClose: function () {
          alert("Transaction was not completed, window closed.");
        },
      });

      handler.openIframe();
    },
    formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.user = this.authUser();
    this.getSubPlans();
    const hash = this.$route.hash;
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1)); // Remove the leading '#' character
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  .loader-rect{
    width: 200px !important;
  }
}
</style>

<template>
  <div>
    <div class="osahan-profile">
      <div class="d-none">
        <div
          class="border-bottom p-3 d-flex align-items-center"
          style="background: #e23744"
        >
          <a class="toggle togglew toggle-2" href="#"><span></span></a>
          <h4 class="font-weight-bold m-0 text-white">Menu</h4>
        </div>
      </div>
      <!-- profile -->
      <div class="container position-relative">
        <div class="py-5 osahan-profile row">
          <div class="col-md-4 mb-3 d-block d-md-block">
            <div
              class="bg-white rounded shadow-sm sticky_sidebar overflow-hidden"
            >
              <router-link to="/profile" class="">
                <div class="d-flex align-items-center p-3">
                  <div class="row">
                    <div class="col-md-3 col-6 mx-auto">
                      <img
                        alt="#"
                        src="../../../public/assets/img/user1.png"
                        class="img-fluid mx-auto"
                      />
                    </div>
                    <div class="col-md-9 col-12 text-md-left text-center">
                      <!-- <h6 class="mb-1 font-weight-bold" v-if="user == null">
                        Chief
                        <i class="feather-check-circle text-success"></i>
                      </h6> -->
                      <h6 class="mb-1 font-weight-bold" v-if="user !== null">
                        {{ user.f_name + " " + user.l_name }}
                        <i class="feather-check-circle text-success"></i>
                      </h6>
                      <!-- <p class="text-muted m-0 small" v-if="user == null">
                        chief@gmail.com
                      </p> -->
                      <p class="text-muted m-0 small" v-if="user !== null">
                        {{ user.email }}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="left">
                    <img
                      alt="#"
                      src="../../../public/assets/img/user1.png"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="right">
                    <h6 class="mb-1 font-weight-bold">
                      Gurdeep Singh
                      <i class="feather-check-circle text-success"></i>
                    </h6>
                    <p class="text-muted m-0 small">iamosahan@gmail.com</p>
                  </div> -->
                </div>
              </router-link>

              <!-- profile-details -->
              <div class="bg-white profile-details">

                  <router-link
                  to="/profile"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                   v-if="this.user !== null"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-user bg-secondary text-white p-2 rounded-circle mr-2"
                      ></i>
                      Profile
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                
                <router-link
                  :to="{ name: 'Orders', hash: '#orders' }"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                    v-if="this.user !== null"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-shopping-cart bg-primary text-white p-2 rounded-circle mr-2"
                      ></i>
                      My Orders
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
              
                   <router-link
                  :to="{ name: 'ScheduledMeals', hash: '#scheduled' }"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                    v-if="this.user !== null"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-info bg-info text-white p-2 rounded-circle mr-2"
                      ></i>
                        Scheduled Meals
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                  :to="{ name: 'Subscription', hash: '#subscription' }"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                    v-if="this.user !== null"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-info bg-info text-white p-2 rounded-circle mr-2"
                      ></i>
                       Subscription Plan
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                 :to="{ name: 'Help', hash: '#help' }"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-phone bg-success text-white p-2 rounded-circle mr-2"
                      ></i>
                      Help & Support
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                       :to="{ name: 'Terms', hash: '#terms' }"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-info bg-dark text-white p-2 rounded-circle mr-2"
                      ></i>
                      Term & Conditions
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                 :to="{ name: 'About', hash: '#aboutus' }"
                  class="d-flex w-100 align-items-center px-3 py-4"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-users bg-warning text-white p-2 rounded-circle mr-2"
                      ></i>
                      About Us
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                 :to="{ name: 'Privacy', hash: '#privacy' }"
                  class="d-flex w-100 align-items-center px-3 py-4"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-lock bg-info text-white p-2 rounded-circle mr-2"
                      ></i>
                      Privacy and Return policy
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>
                <router-link
                 to="/"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-home bg-success text-white p-2 rounded-circle mr-2"
                      ></i>
                      Back to Home
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>

                 <router-link
                  to="/sign-in"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                   v-if="this.user === null"
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-log-in bg-danger text-white p-2 rounded-circle mr-2"
                      ></i>
                      Login
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </router-link>


                <a
                  href="#"
                  class="d-flex w-100 align-items-center border-bottom px-3 py-4"
                   @click="Logout"
                   v-else
                >
                  <div class="left mr-3">
                    <h6 class="font-weight-bold m-0 text-dark">
                      <i
                        class="feather-log-in bg-danger text-white p-2 rounded-circle mr-2"
                      ></i>
                      Logout
                    </h6>
                  </div>
                  <div class="right ml-auto">
                    <h6 class="font-weight-bold m-0">
                      <i class="feather-chevron-right"></i>
                    </h6>
                  </div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: null,
      cartItems: {},
      zones: {},
      selectedZone: null,
    };
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
      Logout(){
       localStorage.removeItem('auth_info');
        localStorage.removeItem('token');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('cartItems');
        localStorage.clear()
       this.$router.push({name: 'Login'});
    },
    getZones() {
      this.$api.get(this.baseUrl("zone/list")).then((res) => {
        this.zones = res.data;
        this.selectedZone = res.data[0];
      });
    },
  },
  mounted() {
    this.user = this.authUser();
    this.getZones();
    this.displayCart();
  },
};
</script>
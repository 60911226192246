<template>
  <div>
    <div class="loader" v-if="showPage == false">
      <div class="align-items-center pt-5">
        <div class="text-center">
          <img
            alt="#"
            class="img-pulsate"
            src="../../../public/assets/img/logo.png"
          />
        </div>
      </div>
    </div>
    <div class="osahan-home-page" v-else>
      <div
        class="p-3 d-block d-md-none"
        style="background-color: white !important"
      >
        <div class="row align-items-center">
          <div class="col-2">
            <router-link to="home.html" class="mb-0">
              <img
                alt="#"
                class="img-fluid"
                src="../../../public/assets/img/logo.png"
              />
            </router-link>
            <!-- brand-wrap.// -->
          </div>
          <div class="col-10 d-flex align-items-center pl-5">
            <div class="dropdown">
              <a
                class="text-dark dropdown-toggle d-flex align-items-center py-3"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div>
                  <i
                    class="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"
                  ></i>
                </div>
                <div>
                  <p class="text-muted mb-0 small">Select Location</p>
                  {{ selectedZone ? selectedZone.name : truncateWord(currentAddress) }}
                </div>
              </a>
              <div
                class="dropdown-menu p-0 drop-loc"
                aria-labelledby="navbarDropdown"
              >
                <div class="osahan-country">
                  <div class="filter">
                    <h6 class="px-3 py-3 bg-light pb-1 m-0 border-bottom">
                      Choose your Zone
                    </h6>
                    <div
                      class="custom-control border-bottom px-0 custom-radio"
                      v-for="(zone, i) in zones"
                      :key="i"
                      @click="pickZone(zone)"
                    >
                      <input
                        type="radio"
                        :id="'customRadio' + zone.id"
                        name="location"
                        class="custom-control-input"
                        v-model="selectedZone"
                        :value="zone"
                      />
                      <label
                        class="custom-control-label py-3 w-100 px-3"
                        :for="'customRadio' + zone.id"
                        >{{ zone.name }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="pt-3" style="font-weight: bolder; font-size: 23px">
          What would you <br />
          like to eat?
        </p>
        <div class="input-group mt-3 rounded shadow-sm overflow-hidden">
          <div class="input-group-prepend">
            <button
              class="border-0 btn btn-outline-secondary text-dark btn-block"
            >
              <i class="feather-search"></i>
            </button>
          </div>

          <input
            type="text"
            class="shadow-none border-0 form-control pt-4 pb-4"
            placeholder="Search for meals..."
               v-model="searchQuery"
             @keyup.enter="handleEnterKey"
            style="border: 1px solid #f7f7f7 !important"
          />
        </div>
      </div>
      <!-- Filters -->
      <div class="container">
        <carousel :perPage="8" class="d-none d-md-block">
          <slide v-for="(cat, i) in categories" :key="i">
            <div>
              <div class="px-1 py-3">
                <router-link
                  class="bg-white rounded d-block p-2 text-center shadow-sm"
                  :to="'/category/' + cat.id"
                >
                  <img
                    alt="#"
                    :src="
                      'https://backend.seid.in/storage/category/' + cat.image
                    "
                    class="img-fluid mb-2 w-100"
                  />
                  <p class="m-0 small">{{ cat.name }}</p>
                </router-link>
              </div>
            </div>
          </slide>
        </carousel>
        <carousel :perPage="4" class="d-block d-md-none">
          <slide v-for="(cat, i) in categories" :key="i">
            <div>
              <div class="px-1 py-3">
                <router-link
                  class="bg-white rounded d-block p-2 text-center shadow-sm"
                  :to="'/category/' + cat.id"
                >
                  <img
                    alt="#"
                    :src="
                      'https://backend.seid.in/storage/category/' + cat.image
                    "
                    class="img-fluid mb-2 w-100"
                  />
                  <p class="m-0 small">{{ cat.name }}</p>
                </router-link>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <!-- offer sectio slider -->
      <div class="bg-white" v-if="banners.length">
        <div class="container" v-if="banners.length">
          <div class="offer-slider" v-if="banners.length">
            <carousel :perPage="4" class="d-none d-md-block">
              <slide v-for="(ban, i) in banners" :key="i">
                <div class="cat-item px-1 py-3">
                  <router-link class="d-block text-center shadow-sm" to="">
                    <img
                      alt="#"
                      :src="
                        'https://backend.seid.in/storage/banner/' + ban.image
                      "
                      class="img-fluid rounded"
                    />
                  </router-link>
                </div>
              </slide>
            </carousel>
            <carousel :perPage="2" class="d-block d-md-none">
              <slide v-for="(ban, i) in banners" :key="i">
                <div class="cat-item px-1 py-3">
                  <router-link class="d-block text-center shadow-sm" to="">
                    <img
                      alt="#"
                      :src="
                        'https://backend.seid.in/storage/banner/' + ban.image
                      "
                      class="img-fluid rounded"
                    />
                  </router-link>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <!-- Modal overlay -->
      <div class="modal-overlay" :class="{ active: showModal }">
        <!-- Modal content -->
        <div class="modal-content py-3">
          <div class="container pt-3">
            <button
              class="float-right"
              style="bachground-color: transparent; border: none"
              @click="closeModal"
            >
              <i class="bi bi-x" style="font-size: 20px"></i>
            </button>
            <div class="row">
              <div class="col-md-5">
                <img
                  :src="
                    'https://backend.seid.in/storage/product/' +
                    selectedMeal.image
                  "
                  alt=""
                  class="img-fluid pb-3"
                />
              </div>
              <div class="col-md-7">
                <h2 style="font-size: 19px">{{ selectedMeal.name }}</h2>
                <p>{{ selectedMeal.category && selectedMeal.category.name }}</p>
                <div class="row">
                  <div class="col-md-5 col-12">
                    <p style="font-size: 17px; color: #e23744">
                      ₦ {{ (checkItemInCart(selectedMeal.id) ? checkItemQuantity(selectedMeal.id) : 1) * selectedMeal.price }}
                    </p>
                  </div>
                  <!-- <div class="col-md-6 col-6">
                    <h2 style="font-size: 19px">Quantity</h2>
                  </div> -->
                  <div class="col-md-7 col-12">
                    <div>
                      <div class="float-right">
                        <button :disabled="checkItemQuantity(selectedMeal.id) === 0" class="dec-button mr-3" @click="minus(selectedMeal.id)">
                          -
                        </button>
                        {{ checkItemInCart(selectedMeal.id) ? checkItemQuantity(selectedMeal.id) : quantity }}
                        <button class="dec-button ml-3" @click="add(selectedMeal,selectedMeal.id)">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <h2 style="font-size: 19px" class="pt-3">Description</h2>
                <p>
                  {{ selectedMeal.description }}
                </p>
              </div>

              <div
                class="col-md-12"
                v-if="selectedMeal.add_ons && selectedMeal.add_ons.length"
              >
                <h2 style="font-size: 19px" class="pt-3">
                  Extra <span style="color: #e23744">(Optional)</span>
                </h2>
              </div>
              <div
                class="col-md-12 mb-2"
                v-for="(s, i) in selectedMeal.add_ons"
                :key="i"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            type="checkbox"
                            name=""
                            class="form-control"
                            :value="checkCheckedID(s.id)"
                            :checked="checkChecked(s.id)"
                            @change="addToSelectedAddons(s,selectedMeal.id)"
                            style="max-width: 15px;cursor:pointer;"
                          />
                          <label class="pt-2 pl-2" :for="s.name">{{
                            s.name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <p class="text-gray mb-3 time">
                          <span
                            v-if="s.quantity"
                            class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 float-right"
                            >₦ {{ s.price * s.quantity }}</span
                          >
                          <span
                            v-else
                            class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 float-right"
                            >₦ {{ s.price }}</span
                          >
                        </p>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <div class="float-right">
                            <button :disabled="checkAddonQuantity(s.id,selectedMeal.id) === 1 || !checkSelectedAddon(s.id,selectedMeal.id)" class="dec-button mr-3" @click="minusAd(s, selectedMeal.id)">
                              -
                            </button>
                               {{ checkSelectedAddon(s.id,selectedMeal.id) ? checkAddonQuantity(s.id,selectedMeal.id) : 1 }}
                            <button :disabled="!checkSelectedAddon(s.id,selectedMeal.id)" class="dec-button ml-3" @click="addAd(s,selectedMeal.id)">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary mt-3 mb-3"
            @click="addToCart(selectedMeal)"
            :disabled="checkItemInCart(selectedMeal.id)"
          >
            {{checkItemInCart(selectedMeal.id) ? "Already In Cart" : "Add To Cart"}}
          </button>
        </div>
      </div>
      <div class="container">
        <!-- Trending this week -->
        <div class="pt-4 pb-3 title d-flex align-items-center">
          <h5 class="m-0">Trending this week</h5>
          <router-link class="font-weight-bold ml-auto" to="/trending"
            >View all <i class="feather-chevrons-right"></i
          ></router-link>
        </div>
        <!-- slider -->
        <div class="trending-sliders">
          <div class="row">
            <div class="col-md-3 mb-3" v-for="(l, i) in latestMeals" :key="i">
              <div class="osahan-slider-item">
                <div
                  class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
                >
                  <div class="list-card-image">
                    <div class="star position-absolute">
                      <span class="badge badge-success"
                        ><i class="feather-star"></i> 3.1 (300+)</span
                      >
                    </div>
                    <div class="favourite-heart text-danger position-absolute">
                      <a href="#"><i class="feather-heart"></i></a>
                    </div>
                    <div class="member-plan position-absolute">
                      <span class="badge badge-dark"
                        >{{ l.restaurant_name }} |
                        {{
                          l.min_delivery_time + "-" + l.max_delivery_time
                        }}mins</span
                      >
                    </div>
                    <div @click="openModal(l)" style="cursor: pointer">
                      <img
                        alt="#"
                        :src="
                          'https://backend.seid.in/storage/product/' + l.image
                        "
                        width="100%"
                      />
                    </div>
                  </div>
                  <div class="p-3 position-relative">
                    <div class="list-card-body">
                      <h6 class="mb-1">
                        <div @click="openModal(l)" class="text-black">
                          {{ l.name }}
                        </div>
                      </h6>
                      <p class="text-gray mb-3">
                        {{ l.category && l.category.name }}
                      </p>
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <p class="text-gray mb-3 time">
                            <span
                              class="bg-light text-dark rounded-sm pb-1 pt-1 pr-2"
                              ><i class=""></i>₦ {{ l.price }}</span
                            >
                          </p>
                        </div>
                        <div class="col-md-6 col-6">
                          <button
                            class="btn btn-primary float-right"
                            @click="openModal(l)"
                          >
                            <i
                              class="feather-shopping-cart"
                              style="text-style: normal; font-size: 14px"
                            >
                              Order</i
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


         <div v-if="latestMeals.length === 0">
              <div class="row d-flex align-items-center justify-content-center py-3">
                <div class="col-md-4 py-5">
                 <div class="text-center py-5">
                    <p class="h4 mb-4"><i class="feather-search bg-primary text-white rounded p-2"></i></p>
                    <p class="fw-bold text-dark h5">Nothing found</p>
                    <p>Sorry, we are not in your location yet, please select from a zone.</p>
                </div>
                </div>
            </div>
         </div>
        </div>

        <div class="pt-4 pb-3 title d-flex align-items-center">
          <h5 class="m-0">Recommended Meals</h5>
          <router-link class="font-weight-bold ml-auto" to="/recommended"
            >View all <i class="feather-chevrons-right"></i
          ></router-link>
        </div>
        <div class="most_popular">
          <div class="row">
            <div
              class="col-md-3 mb-3"
              v-for="(l, i) in recomendedMeals"
              :key="i"
            >
              <div class="osahan-slider-item">
                <div
                  class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm"
                >
                  <div class="list-card-image">
                    <div class="star position-absolute">
                      <span class="badge badge-success"
                        ><i class="feather-star"></i> 3.1 (300+)</span
                      >
                    </div>
                    <div class="favourite-heart text-danger position-absolute">
                      <a href="#"><i class="feather-heart"></i></a>
                    </div>
                    <div class="member-plan position-absolute">
                      <span class="badge badge-dark"
                        >{{ l.restaurant_name }} |
                        {{
                          l.min_delivery_time + "-" + l.max_delivery_time
                        }}mins</span
                      >
                    </div>
                    <div @click="openModal(l)" style="cursor: pointer">
                      <img
                        alt="#"
                        :src="
                          'https://backend.seid.in/storage/product/' + l.image
                        "
                        width="100%"
                      />
                    </div>
                  </div>
                  <div class="p-3 position-relative">
                    <div class="list-card-body">
                      <h6 class="mb-1">
                        <div @click="openModal(l)" class="text-black">
                          {{ l.name }}
                        </div>
                      </h6>
                      <p class="text-gray mb-3">
                        {{ l.category && l.category.name }}
                      </p>
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <p class="text-gray mb-3 time">
                            <span
                              class="bg-light text-dark rounded-sm  pb-1 pt-1 pr-2"
                              ><i class=""></i>₦ {{ l.price }}</span
                            >
                          </p>
                        </div>
                        <div class="col-md-6 col-6">
                          <button
                            class="btn btn-primary float-right"
                            @click="openModal(l)"
                          >
                            <i
                              class="feather-shopping-cart"
                              style="text-style: normal; font-size: 14px"
                            >
                              Order</i
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         <div v-if="recomendedMeals.length === 0">
              <div class="row d-flex align-items-center justify-content-center py-3">
                <div class="col-md-4 py-5">
                 <div class="text-center py-5">
                    <p class="h4 mb-4"><i class="feather-search bg-primary text-white rounded p-2"></i></p>
                    <p class="fw-bold text-dark h5">Nothing found</p>
                    <p>Sorry, we are not in your location yet, please select from a zone.</p>
                </div>
                </div>
            </div>
         </div>

        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Define the keyframes for the pulsating animation */
@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the pulsating animation to the image */
.img-pulsate {
  animation: pulsate 2s infinite;
  width: 20% !important;
}
.loader {
  height: 100vh;
}
/* Styles for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-overlay.active {
  display: flex;
}

/* Styles for the modal content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 500px;
  border-radius: 10px;
  overflow-y: auto;
}
.dec-button {
  background-color: #e23744;
  border: 1px solid #e23744;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dec-button:disabled {
  opacity: 0.5;
}

/* Responsive styles */
@media (max-width: 600px) {
  .modal-content {
    max-width: 80%;
    height: 70%;
    scroll-behavior: smooth;
  }
}
</style>
<script>
import { Carousel, Slide } from "vue-carousel";
import { EventBus } from "@/eventBus.js";
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      showModal: false,
      showPage: false,
      quantity: 0,
      latestMeals: {},
      selectedMeal: {},
      cartItems: [],
      categories: {},
      zones: {},
      selectedZone: null,
      banners: [],
      addon_quantity: 1,
      selectedAddons: [],
      cartAddons: [],
      recomendedMeals: {},
      searchQuery: "",
      currentAddress: ""
    };
  },
  components: {
    Carousel,
    Slide,
    swal,
    
  },
  watch:{
    cartItems(newValue){
      this.checkItemQuantity(this.selectedMeal.id)
      this.checkItemInCart(this.selectedMeal.id)
    }
   
  },
  methods: {
    openModal(meal) {
      this.selectedMeal = meal;
      console.log(meal)
      // cheeck from cart the addon with the selected meal addon 
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant = cartItems.some((item) => item.product.id === meal.id)
      if(checkRestaurant){
          let addon = cartItems.find((item) => item.product.id === meal.id).addons
          console.log(addon)
          this.cartAddons = addon
          this.selectedAddons = addon
      }
      else{
        this.cartAddons = []
        this.selectedAddons = []
      }
      
      this.showModal = true;
    },
    checkCheckedID(id){
       let idExist = this.cartAddons.some((item) => item.id === id)
       let checkedID;
       if(idExist){
          let checkID = this.cartAddons.find((item) => item.id === id).id
          checkedID = checkID
       }
       else{
        checkedID = id
       }
       return checkedID
    },
    checkChecked(id){
      let idExist = this.cartAddons.some((item) => item.id === id)
       let checkedID;
       if(idExist){
          checkedID = true
       }
       else{
        checkedID = false
       }
       return checkedID
    },
      handleEnterKey(){
      this.$router.push(`/search?query=${this.searchQuery}`)
    },
    closeModal() {
      this.showModal = false;
    },
     checkItemInCart(id){
      let res = this.cartItems.some((item) => item.product.id === id)
      return res
    },
   checkItemQuantity(id) {
      let qty;
      // let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let res = this.cartItems.some((item) => item.product.id === id);
      if(res){
        let qtyVal = this.cartItems.find((item) => item.product.id === id);
        qty = qtyVal.quantity
      }
      else{
        qty = this.quantity
      }
      return qty
    },
    add(product,id) {
     let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant =  cartItems.some((item) => item.product.restaurant_id !== product.restaurant_id)
      //  check for restarurant if it's in cart
       if (checkRestaurant) {
        this.$toast.error(
          "You cannot add food from different restaurants to the cart",
          {
            // Add your preferred toast configuration for displaying an error message
          }
      )
    }
      else{
     const cartItem = cartItems.find(item => item.product.id === id);
    //  if item exist in cart, add quantity else push to cart a new item from same restaurant
      if (cartItem) {
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            quantity: item.quantity + 1
          } : item)
          
       localStorage.setItem("cartItems", JSON.stringify(newCartItems));
       this.cartItems = newCartItems
       this.$toast.success(
          "Item quantity updated",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
      } else {      
      // Add the new item to the cart
        cartItems.push({
          quantity: 1,
          product: product,
          addons: this.selectedAddons,
          addon_quantity: this.addon_quantity,
        });

      //  this.closeModal();
        this.$toast.success(
          "Item added to cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        )
  
        // Update the localStorage with the updated cart items
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
         this.cartItems = cartItems


       
        //  this.closeModal();
      }
      }
    },
    minus(id) {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const cartItem = cartItems.find(item => item.product.id === id);
      if (cartItem) {
        if(cartItem.quantity === 1){
        let removedItem = cartItems.filter(item => item.product.id !== id)
        localStorage.setItem("cartItems", JSON.stringify(removedItem));
        this.cartItems = removedItem
          this.$toast.success(
            "Item removed from cart",
            {
              position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            }
          );
        }
        else{
       let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            quantity: item.quantity - 1
          } : item)

       localStorage.setItem("cartItems", JSON.stringify(newCartItems));
        this.cartItems = newCartItems
        this.$toast.success(
          "Item quantity updated",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
        }
      
      } 
    },

      addAd(s, id) {
      
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingAddonIndex = this.selectedAddons.findIndex(
        (item) => item.addon === s.name
      );

      if (existingAddonIndex !== -1) {
        // If the addon already exists, update its quantity
        this.selectedAddons[existingAddonIndex].quantity++;
        this.selectedAddons[existingAddonIndex].selected = true; // Mark the addon as selected

        // update the cart with this addon, check cartitems for exact cart and add this addon change to it
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            addons: this.selectedAddons
          } : item)
        this.cartItems = newCartItems
        localStorage.setItem("cartItems", JSON.stringify(newCartItems));
          this.$toast.success(
          "Top on updated in cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          }
        );
      }

      
      // this.closeModal();
      this.showModal = true;
    },
    checkAddonQuantity(id, product_id) {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let checkRestaurant = cartItems.some((item) => item.product.id === product_id)
      let qty;
      //  check product in cart, get the addon and compare with the addon id to get qty
      if(checkRestaurant){
        let addon = cartItems.find((item) => item.product.id === product_id).addons
          // let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
          let res = addon.some((item) => item.id === id);
          if(res){
            let qtyVal = addon.find((item) => item.id === id);
            console.log(qtyVal)
            qty = qtyVal.quantity
          }
          else{
            qty = 1
          }
      }
      else{
        qty = 1
      }
     return qty
    
    },
   
    checkSelectedAddon(id, product_id){
      let res = this.cartAddons.some((item) => item.id === id)
      console.log(res)
      return res
    },
     addToSelectedAddons(s, id) { 
       // item must be in cart before addons can be added;
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
       let checkRestaurant =  cartItems.some((item) => item.product.id === id)
        if(checkRestaurant){
             // check if addon is checked
                  // Check if the addon is checked push addon as a new addon with quantity 1 else remove it if unchecked entirely
                let existingAddonIndex =  this.selectedAddons.some((item) => item.id === s.id)

                if (existingAddonIndex) {
                  // If the addon already exists, update its quantity
                  let removedAddon =  this.selectedAddons.filter(item => item.id !== s.id)
                  this.selectedAddons = removedAddon
                  this.cartAddons = removedAddon

                  // update the cart with this addon, check cartitems for exact cart and add this addon change to it
                    let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
                      {
                        ...item, 
                        addons: this.selectedAddons
                      } : item)
                    this.cartItems = newCartItems
                  localStorage.setItem("cartItems", JSON.stringify(newCartItems));

                } else {
                  this.selectedAddons.push({
                    id: s.id,
                    addon: s.name,
                    quantity: 1,
                    selected: true,
                    price: s.price,
                  });

                  this.cartAddons.push({
                    id: s.id,
                    addon: s.name,
                    quantity: 1,
                    selected: true,
                    price: s.price,
                  });
                  
                  // update the cart with this addon
                   let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
                      {
                        ...item, 
                        addons: this.selectedAddons
                      } : item)
                    this.cartItems = newCartItems
                  localStorage.setItem("cartItems", JSON.stringify(newCartItems));
                  // this.selectedAddons = newAddons
              }
        }
        else{
          this.$toast.error(
            "Kindly add the product to cart before adding an addon!",
            {
              // Add your preferred toast configuration for displaying an error message
            }
          )
        }
      
     
      this.closeModal();
      this.showModal = true;
      console.log(this.selectedAddons)
    },
     minusAd(s,id) {
        // If the quantity becomes 0, remove the addon from selectedAddons
         let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const addonIndex = this.selectedAddons.findIndex(
          (item) => item.addon === s.name
        );
        if (addonIndex !== -1) {
          this.selectedAddons[addonIndex].quantity--;

            // update the cart with this addon, check cartitems for exact cart and add this addon change to it
        let newCartItems =  cartItems.map((item)=> item.product.id === id ? 
          {
            ...item, 
            addons: this.selectedAddons
          } : item)
          this.cartItems = newCartItems
          localStorage.setItem("cartItems", JSON.stringify(newCartItems));
           this.$toast.success(
          "Top on updated in cart",
          {
            position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          })
        }
      
      this.closeModal();
      this.showModal = true;
       console.log(this.selectedAddons)
    },
    getLatestMeals() {
      this.$api
        .get(this.baseUrl("products/latest?type=all&limit=0&offset=0"))
        .then((res) => {
          this.latestMeals = res.data.products;
          this.showPage = true;
        });
    },
    getRecomendedMeals() {
      this.$api
        .get(this.baseUrl("products/recommended?type=all&limit=0&offset=0"))
        .then((res) => {
          this.recomendedMeals = res.data.products;
          this.showPage = true;
        });
    },
    getCategories() {
      this.$api.get(this.baseUrl("categories")).then((res) => {
        this.categories = res.data;
        this.showPage = true;
      });
    },
    pickZone(zone) {
      localStorage.setItem("zone", JSON.stringify(zone));
      const zoneValue = JSON.parse(localStorage.getItem("zone"));
      this.selectedZone = zoneValue;
    },

    truncateWord(longSentence){
      const truncatedSentence = longSentence.slice(0, 20) + "…";
      return truncatedSentence;
    },
       // get current location
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
           this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
     async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBNDEeq0RAFJG6Iai_gTHIOsNE5_0EnV_c"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          console.log(data.results[0].formatted_address);
          this.currentAddress = data.results[0].formatted_address;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    getZones() {
      this.$api
        .get(this.baseUrl("zone/list"))
        .then((res) => {
          // Assuming res.data is an array of zones
          if (Array.isArray(res.data) && res.data.length > 0) {
            this.zones = res.data;
              const zoneValue = JSON.parse(localStorage.getItem("zone"));

             if(zoneValue){
              this.selectedZone = zoneValue;
            }
            else{
              this.selectedZone = null
                // localStorage.setItem("zone", JSON.stringify(this.selectedZone));
            }
            

            // this.selectedZone = res.data[0];

            // // Get the value from the zone storage
            // const zoneValue = JSON.parse(localStorage.getItem("zone"));

            // // If the value is empty (null or undefined), set the value in the zone storage
            // if (!zoneValue) {
            //   this.selectedZone = res.data[0];
            //   localStorage.setItem("zone", JSON.stringify(this.selectedZone));
            // } else {
            //   this.selectedZone = zoneValue;
            // }
          } else {
            console.error("Invalid response data for zones:", res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching zones:", error);
          // You might want to handle the error or show a message to the user.
        });
    },
    getBanners() {
      this.$api.get(this.baseUrl("banners")).then((res) => {
        this.banners = res.data.banners;
        this.showPage = true;
      });
    },
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    addToCart(product) {
      // Retrieve the existing cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

      // Check if the product being added has the restaurant_id property
      if (!product.restaurant_id) {
        this.$toast.error("Invalid product: missing restaurant_id", {
          // Add your preferred toast configuration for displaying an error message
        });
        return;
      }

      // Check if any product in the cart already has a different restaurant_id
      if (
        cartItems.some(
          (item) => item.product.restaurant_id !== product.restaurant_id
        )
      ) {
        this.$toast.error(
          "You cannot add food from different restaurants to the cart",
          {
            // Add your preferred toast configuration for displaying an error message
          }
        );
        return;
      }

      // Add the new item to the cart
      cartItems.push({
        quantity: 1,
        product: product,
        addons: this.selectedAddons,
        addon_quantity: this.addon_quantity,
      });

      swal({
        title: "Item Added to cart",
        // text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "success",
        buttons: ["Continue Shopping", "Checkout"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$router.push("/cart");
        } else {
        }
      });

      // Update the localStorage with the updated cart items
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      this.cartItems = cartItems
      // Rest of your code...
      EventBus.$emit("itemAddedToCart");
      // this.closeModal();
    },
  },
  mounted() {
     // get present location
    this.locatorButtonPressed();

    this.getLatestMeals();
    this.getCategories();
    this.getRecomendedMeals();
    this.getBanners();
    this.displayCart();
    // End of use strict
  },
  beforeMount() {
    this.getZones();
  },
};
</script>
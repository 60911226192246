<template>
  <div>
    <template>
      <div>
        <div class="osahan-profile">
          <div class="d-none">
            <div class="bg-primary border-bottom p-3 d-flex align-items-center">
              <a
                class="toggle togglew toggle-2 hc-nav-trigger hc-nav-1"
                href="#"
                role="button"
                aria-controls="hc-nav-1"
                ><span></span
              ></a>
              <h4 class="fw-bold m-0 text-white">Profile</h4>
            </div>
          </div>

          <div class="container position-relative">
            <div class="py-5 osahan-profile row">
              <div class="col-md-4 mb-3">
                <ProfileSidebar />
              </div>
              <div class="col-md-8 mb-3">
                <div class="osahan-cart-item-profile" id="help">
                  <div class="box bg-white mb-3 shadow-sm rounded">
                    <div class="p-3 d-flex align-items-center">
                      <i class="feather-message-circle display-4"></i>
                      <div class="ms-3">
                        <h6 class="text-dark mb-2 mt-0">Help Forum</h6>
                        <p class="mb-0 text-muted">
                          Find the answer to any question, from the basics ...
                        </p>
                      </div>
                    </div>
                    <div
                      class="overflow-hidden border-top d-flex align-items-center p-3"
                    >
                      <a class="fw-bold d-block" href="#">
                        Swiggiweb Help Forum.
                      </a>
                      <i
                        class="feather-arrow-right-circle ms-auto text-primary"
                      ></i>
                    </div>
                  </div>
                  <div class="box bg-white mb-3 shadow-sm rounded">
                    <div class="p-3 d-flex align-items-center">
                      <i class="feather-lock display-4"></i>
                      <div class="ms-3">
                        <h6 class="text-dark mb-2 mt-0">Safety Center</h6>
                        <p class="mb-0 text-muted">
                          Want to learn more about setting up and managing ...
                        </p>
                      </div>
                    </div>
                    <div
                      class="overflow-hidden border-top d-flex align-items-center p-3"
                    >
                      <a class="fw-bold d-block" href="#">
                        Swiggiweb Safety Center.
                      </a>
                      <i
                        class="feather-arrow-right-circle ms-auto text-primary"
                      ></i>
                    </div>
                  </div>
                  <div id="basics">
                    <div class="mb-2 mt-3">
                      <h5 class="font-weight-semi-bold mb-0">Basics</h5>
                    </div>

                    <div id="basicsAccordion">
                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="basicsHeadingOne">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn p-3 collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#basicsCollapseOne"
                              aria-expanded="false"
                              aria-controls="basicsCollapseOne"
                            >
                              Do you have any built-in caching?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="basicsCollapseOne"
                          class="collapse"
                          aria-labelledby="basicsHeadingOne"
                          data-bs-parent="#basicsAccordion"
                          style=""
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>
                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="basicsHeadingTwo">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn p-3 collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#basicsCollapseTwo"
                              aria-expanded="false"
                              aria-controls="basicsCollapseTwo"
                            >
                              Can I add/upgrade my plan at any time?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="basicsCollapseTwo"
                          class="collapse"
                          aria-labelledby="basicsHeadingTwo"
                          data-bs-parent="#basicsAccordion"
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>
                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="basicsHeadingThree">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn p-3 collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#basicsCollapseThree"
                              aria-expanded="false"
                              aria-controls="basicsCollapseThree"
                            >
                              What access comes with hosting plan?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="basicsCollapseThree"
                          class="collapse"
                          aria-labelledby="basicsHeadingThree"
                          data-bs-parent="#basicsAccordion"
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>
                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="basicsHeadingFour">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn collapsed p-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#basicsCollapseFour"
                              aria-expanded="false"
                              aria-controls="basicsCollapseFour"
                            >
                              How do I change my password?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="basicsCollapseFour"
                          class="collapse"
                          aria-labelledby="basicsHeadingFour"
                          data-bs-parent="#basicsAccordion"
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="account">
                    <div class="mb-2 mt-3">
                      <h5 class="font-weight-semi-bold mb-0">Account</h5>
                    </div>

                    <div id="accountAccordion">
                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="accountHeadingOne">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn p-3 collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#accountCollapseOne"
                              aria-expanded="false"
                              aria-controls="accountCollapseOne"
                            >
                              How do I change my password?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="accountCollapseOne"
                          class="collapse"
                          aria-labelledby="accountHeadingOne"
                          data-bs-parent="#accountAccordion"
                          style=""
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>

                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="accountHeadingTwo">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn p-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#accountCollapseTwo"
                              aria-expanded="true"
                              aria-controls="accountCollapseTwo"
                            >
                              How do I delete my account?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="accountCollapseTwo"
                          class="collapse show"
                          aria-labelledby="accountHeadingTwo"
                          data-bs-parent="#accountAccordion"
                          style=""
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>

                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="accountHeadingThree">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn collapsed p-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#accountCollapseThree"
                              aria-expanded="false"
                              aria-controls="accountCollapseThree"
                            >
                              How do I change my account settings?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="accountCollapseThree"
                          class="collapse"
                          aria-labelledby="accountHeadingThree"
                          data-bs-parent="#accountAccordion"
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>

                      <div
                        class="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden"
                      >
                        <div id="accountHeadingFour">
                          <h5 class="mb-0">
                            <button
                              class="shadow-none btn w-100 d-flex justify-content-between card-btn collapsed p-3"
                              data-bs-toggle="collapse"
                              data-bs-target="#accountCollapseFour"
                              aria-expanded="false"
                              aria-controls="accountCollapseFour"
                            >
                              I forgot my password. How do I reset it?
                              <span class="card-btn-arrow">
                                <span class="feather-chevron-down"></span>
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="accountCollapseFour"
                          class="collapse"
                          aria-labelledby="accountHeadingFour"
                          data-bs-parent="#accountAccordion"
                        >
                          <div class="card-body border-top p-3 text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid ...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none"
          >
            <div class="row">
              <div class="col">
                <a
                  href="home.html"
                  class="text-dark small fw-bold text-decoration-none"
                >
                  <p class="h4 m-0"><i class="feather-home text-dark"></i></p>
                  Home
                </a>
              </div>
              <div class="col">
                <a
                  href="most_popular.html"
                  class="text-dark small fw-bold text-decoration-none"
                >
                  <p class="h4 m-0"><i class="feather-map-pin"></i></p>
                  Trending
                </a>
              </div>
              <div class="col bg-white rounded-circle mt-n4 px-3 py-2">
                <div class="bg-danger rounded-circle mt-n0 shadow">
                  <a
                    href="checkout.html"
                    class="text-white small fw-bold text-decoration-none"
                  >
                    <i class="feather-shopping-cart"></i>
                  </a>
                </div>
              </div>
              <div class="col">
                <a
                  href="favorites.html"
                  class="text-dark small fw-bold text-decoration-none"
                >
                  <p class="h4 m-0"><i class="feather-heart"></i></p>
                  Favorites
                </a>
              </div>
              <div class="col selected">
                <a
                  href="profile.html"
                  class="text-danger small fw-bold text-decoration-none"
                >
                  <p class="h4 m-0"><i class="feather-user"></i></p>
                  Profile
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ProfileSidebar from "../Menu/ProfileSidebar.vue";
export default {
  components: {
    ProfileSidebar,
  },
   mounted(){
    const hash = this.$route.hash;
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1)); // Remove the leading '#' character
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>
    </div>
</template>
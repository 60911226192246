<template>
  <div>
    <div class="osahan-profile">
      <div class="d-none">
        <div
          class="border-bottom p-3 d-flex align-items-center"
          style="background: #e23744"
        >
          <a class="toggle togglew toggle-2" href="#"><span></span></a>
          <h4 class="font-weight-bold m-0 text-white">Menu</h4>
        </div>
      </div>
      <!-- profile -->
      <div class="container position-relative">
        <div class="py-5 osahan-profile row">
          <div class="col-md-4 mb-3 d-none d-md-block">
            <ProfileSidebar />
          </div>
          <div class="col-md-8 mb-3">
            <div class="rounded shadow-sm p-4 bg-white">
              <h5 class="mb-4">My account</h5>
              <div id="edit_profile">
                <div>
                  <form @submit.prevent="ro">
                       <vue-element-loading
                          :active="loading"
                          color="#FF6700"
                          text="Please wait"
                          spinner="line-wave"
                        />

                    <div class="form-group">
                      <label for="exampleInputName1">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputName1d"
                        v-model="user.f_name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputName1">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputName1"
                        v-model="user.l_name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputNumber1">Mobile Number</label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputNumber1"
                        v-model="user.phone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                    <div class="text-center">
                      <button
                      :disabled="loading"
                      @click="UpdateProfile"
                       type="submit" class="btn btn-primary btn-block">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
                <div class="additional">
                  <div class="change_password my-3">
                    <router-link
                      to="/changepassword"
                      class="p-3 border rounded bg-white btn d-flex align-items-center"
                      >Change Password
                      <i class="feather-arrow-right ml-auto"></i
                    ></router-link>
                  </div>
                  <div class="deactivate_account">
                    <a
                      href="#"
                      class="p-3 border rounded bg-white btn d-flex align-items-center"
                      >Deactivate Account
                      <i class="feather-arrow-right ml-auto"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileSidebar from "./ProfileSidebar.vue";
import VueElementLoading from "vue-element-loading";
export default {
  data() {
    return {
      user: null,
      loading: false,
      cartItems: {},
      zones: {},
      selectedZone: null,
    };
  },
  components: {
    ProfileSidebar,
    VueElementLoading,
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    UpdateProfile(){
      this.loading = true
      let res = {
        f_name: this.user.f_name,
        l_name: this.user.l_name,
        phone: this.user.phone,
        email: this.user.email
      }
      this.$store.dispatch("UpdateProfile",res)
      .then(()=>{
              this.loading = false
        this.$toast.success("Profile details updated!", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
      })
      .catch(()=>{
              this.loading = false
         this.$toast.error("Oops, an error occured updating this details", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
      })
    },
    getZones() {
      this.$api.get(this.baseUrl("zone/list")).then((res) => {
        this.zones = res.data;
        this.selectedZone = res.data[0];
      });
    },
  },
  mounted() {
    this.user = this.authUser();
    this.getZones();
    this.displayCart();
  },
};
</script>
import axios from "axios";


let token = localStorage.getItem('auth_info') != null ? JSON.parse(localStorage.getItem('auth_info')).auth_token :null;
let zoneId = localStorage.getItem('zone') != null ? JSON.parse(localStorage.getItem('zone')).id :null;
let zones = [];
zones.push(zoneId)
const appAxios = axios.create({
    headers:{
        authorization: `Bearer ${token}`,
        'zoneId': JSON.stringify(zones)
    }
});

// before a request is made start the nprogress
// appAxios.interceptors.request.use(config => {
//     NProgress.start()
//     return config
//   })
  
  // before a response is returned stop nprogress
//   appAxios.interceptors.response.use(response => {
//     alert(2)

//     NProgress.done()
//     return response
//   }, error => {
//     console.log(error)
//     NProgress.done()

//     alert(3)
//     return error

//   })
  


export default appAxios

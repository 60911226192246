<template>
  <div>
    <!-- Footer -->
    <div
      class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-md-none d-block"
    >
      <div class="row">
        <div
          class="col selected"
          v-if="this.$router.currentRoute.fullPath == '/'"
        >
          <router-link
            to="/"
            class="text-danger small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-home text-danger"></i></p>
            Home
          </router-link>
        </div>
        <div class="col" v-else>
          <router-link
            to="/"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-home"></i></p>
            Home
          </router-link>
        </div>
        <div
          class="col"
          v-if="this.$router.currentRoute.fullPath !== '/meals'"
        >
          <router-link
            to="/meals"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-map-pin"></i></p>
            Meals
          </router-link>
        </div>
        <div class="col selected" v-else>
          <router-link
            to="/meals"
            class="text-danger small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-map-pin text-danger"></i></p>
            Meals
          </router-link>
        </div>
        <div class="col bg-white rounded-circle mt-n4 px-3 py-2">
          <div class="bg-danger rounded-circle mt-n0 shadow">
            <router-link
              to="/cart"
              class="text-white small font-weight-bold text-decoration-none"
            >
              <i class="feather-shopping-cart"></i>
              <span
                class="text-white"
                style="
                  background-color: #ffc107 !important;
                  border-radius: 50%;
                  position: absolute;
                  width: 20px;
                  font-size: 14px;
                  right: 25px;
                  bottom: 57px;
                "
                >{{ cartItems.length }}</span
              >
            </router-link>
          </div>
        </div>
        <div
          class="col"
          v-if="this.$router.currentRoute.fullPath !== '/trending'"
        >
          <router-link
            to="/trending"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-heart"></i></p>
            Trending
          </router-link>
        </div>
        <div class="col selected" v-else>
          <router-link
            to="/trending"
            class="text-danger small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-heart text-danger"></i></p>
            Trending
          </router-link>
        </div>
        <div class="col" v-if="this.$router.currentRoute.fullPath !== '/menu'">
          <router-link
            to="/menu"
            class="text-dark small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-menu"></i></p>
            Menu
          </router-link>
        </div>
        <div class="col recommended" v-else>
          <router-link
            to="/menu"
            class="text-danger small font-weight-bold text-decoration-none"
          >
            <p class="h4 m-0"><i class="feather-menu text-danger"></i></p>
            Menu
          </router-link>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer class="section-footer border-top bg-dark">
      <div class="container">
        <section class="footer-top padding-y py-5">
          <div class="row">
            <aside class="col-md-4 footer-about">
              <article class="d-flex pb-3">
                <div>
                  <img
                    alt="#"
                    src="../../../public/assets/img/logo.png"
                    class="logo-footer mr-3"
                  />
                </div>
                <div>
                  <h6 class="title text-white">About Us</h6>
                  <p class="text-muted">
                    The Meal Technology Company is the operating company for My
                    Meal Center, a food booking and scheduling app whose aim is
                    to provide individuals with an organized meal plan as well
                    as make it easier to access good and affordable meals,
                    especially at work
                  </p>
                  <div class="d-flex align-items-center">
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Facebook"
                      target="_blank"
                      href="#"
                      ><i class="feather-facebook"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Instagram"
                      target="_blank"
                      href="#"
                      ><i class="feather-instagram"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Youtube"
                      target="_blank"
                      href="#"
                      ><i class="feather-youtube"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Twitter"
                      target="_blank"
                      href="#"
                      ><i class="feather-twitter"></i
                    ></a>
                  </div>
                </div>
              </article>
            </aside>
            <aside class="col-sm-3 col-md-2 text-white">
              <h6 class="title">Company</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/about-us" class="text-muted"
                    >About Us</router-link
                  >
                </li>
                <li>
                  <a href="#" class="text-muted">Careers</a>
                </li>
                <li>
                  <router-link to="/help" class="text-muted"
                    >Help & Support</router-link
                  >
                </li>
                <li>
                  <router-link to="/help" class="text-muted"
                    >Contact Us</router-link
                  >
                </li>
              </ul>
            </aside>
            <aside class="col-sm-3 col-md-2 text-white">
              <h6 class="title">Services</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link href="/home#categories" class="text-muted"
                    >Categories</router-link
                  >
                </li>
                <li>
                  <router-link to="/trending" class="text-muted"
                    >Trending Meals</router-link
                  >
                </li>
                <li>
                  <router-link to="/recommended" class="text-muted"
                    >Recommended Meals</router-link
                  >
                </li>
                <!-- <li>
                  <a href="privacy.html" class="text-muted">Latest Meals</a>
                </li> -->
              </ul>
            </aside>
            <aside class="col-sm-3 col-md-2 text-white">
              <h6 class="title">For users</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/sign-in" class="text-muted">
                    User Login
                  </router-link>
                </li>
                <li>
                  <router-link to="/sign-up" class="text-muted">
                    User register
                  </router-link>
                </li>
                <li>
                  <a href="#" class="text-muted"> Forgot Password </a>
                </li>
                <li>
                  <a href="#" class="text-muted"> Account Setting </a>
                </li>
              </ul>
            </aside>
            <aside class="col-sm-3 col-md-2 text-white">
              <h6 class="title">More Links</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/orders" class="text-muted">
                    My Orders
                  </router-link>
                </li>
                <li>
                  <a href="#" class="text-muted"> Reviews </a>
                </li>
                <li>
                  <router-link to="/privacy-policy" class="text-muted">
                    Privacy Policy
                  </router-link>
                </li>
                <li>
                  <router-link to="/terms" class="text-muted">
                    Terms of use
                  </router-link>
                </li>
              </ul>
            </aside>
          </div>
          <!-- row.// -->
        </section>
      </div>
      <!-- //container -->
      <section class="footer-copyright border-top py-3 bg-light">
        <div class="container d-flex align-items-center">
          <p class="mb-0">© 2020 My meal Center, All rights reserved</p>
          <p class="text-muted mb-0 ml-auto d-flex align-items-center">
            <a href="#" class="d-block"
              ><img
                alt="#"
                src="../../../public/assets/img/appstore.png"
                height="40"
            /></a>
            <a href="#" class="d-block ml-3"
              ><img
                alt="#"
                src="../../../public/assets/img/playmarket.png"
                height="40"
            /></a>
          </p>
        </div>
      </section>
    </footer>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus.js";
export default {
  data() {
    return {
      user: null,
      cartItems: {},
      zones: {},
      selectedZone: null,
    };
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    getZones() {
      this.$api.get(this.baseUrl("zone/list")).then((res) => {
        this.zones = res.data;
        this.selectedZone = res.data[0];
      });
    },
  },
  mounted() {
    console.log(this.$router.currentRoute.fullPath)
    this.user = this.authUser();
    this.getZones();
    this.displayCart();
    EventBus.$on("itemAddedToCart", () => {
      // Update cart item count
      this.displayCart();
    });
  },
  beforeDestroy() {
    // Clean up event listener when the component is destroyed
    EventBus.$off("itemAddedToCart");
  },
};
</script>

import axios from "axios";
import { apiUrl } from "./config";


export const auth ={
    state: {
        // Your initial state properties go here
        token: localStorage.getItem("token") || "",
        isAuthenticated: false,
        userInfo: {}
      },
      getters: {
        // Your getters go here
      },
      mutations: {
        // Your mutations go here
        Token(state, token) {
            state.token = token;
            state.isAuthenticated = true;
        },
        userInfo(state, data) {
            state.userInfo = data;
          },
      },
      actions: {
        // Your actions go here
          // login here
          Login: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
              axios
                .post(`${apiUrl}auth/login`, payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    const token = data.token;
                    const userInfo = data.user_details;
                    localStorage.setItem("token", token);
                    commit("Token", token);
                    commit("userInfo", userInfo);
                    resolve(data);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
           // forgot password api
           Forgot: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
              axios
                .post(`${apiUrl}auth/forgot-password`, payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    resolve(data);
                    commit();
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
            // Register a user api
          Register: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
              axios
                .post(`${apiUrl}auth/sign-up`, payload)
                .then(({ data, status }) => {
                  if (status === 201) {
                    resolve(data);
                    commit();
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
            },
               // set new password api
           ResetPassword: ({ commit }, payload) => {
            const res = {
              email: payload.email,
              reset_token: payload.reset_token,
              password: payload.password,
              confirm_password: payload.password_confirmation,
            };
            
            return new Promise((resolve, reject) => {
              axios
                .put(`${apiUrl}auth/reset-password`, res)
                .then(({ data, status }) => {
                  if (status === 200) {
                    resolve(data);
                    commit();
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
          
          // update profile
          UpdateProfile:  ({ commit }, payload) => {
            var bodyFormData = new FormData();
            bodyFormData.append("l_name", payload.l_name);
            bodyFormData.append("f_name", payload.f_name);
            bodyFormData.append("email", payload.email);
            bodyFormData.append("phone", payload.phone);
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios({
                method: "post",
                url: `${apiUrl}customer/update-profile`,
                data: bodyFormData,
                config: {},
                headers: {
                  Authorization:  `Bearer ${token}`
                }
              })
                .then(({ data, status }) => {
                  if (status === 200) {
                    resolve(data);   
                    commit()
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

             // change password
             ChangePassword:  ({ commit }, payload) => {
              var bodyFormData = new FormData();
              bodyFormData.append("password", payload.password);
              bodyFormData.append("password_confirmation", payload.password_confirmation);
              bodyFormData.append("l_name", payload.l_name);
              bodyFormData.append("f_name", payload.f_name);
              bodyFormData.append("email", payload.email);
              bodyFormData.append("phone", payload.phone);
              let token =
              localStorage.getItem("auth_info") != null
                ? JSON.parse(localStorage.getItem("auth_info")).auth_token
                : null;
              return new Promise((resolve, reject) => {
                axios({
                  method: "post",
                  url: `${apiUrl}customer/update-profile`,
                  data: bodyFormData,
                  config: {},
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                })
                  .then(({ data, status }) => {
                    if (status === 200) {
                      resolve(data);   
                      commit()
                    }
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            },

      },  
}
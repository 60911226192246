<template>
  <div>
    <header class="section-header">
      <section class="header-main shadow-sm bg-white">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-1">
              <router-link to="/" class="brand-wrap mb-0">
                <img
                  alt="#"
                  class="img-fluid"
                  src="../../../public/assets/img/logo.png"
                />
              </router-link>
              <!-- brand-wrap.// -->
            </div>
            <div class="col-3 d-flex align-items-center m-none">
              <div class="dropdown mr-3">
                <a
                  class="text-dark dropdown-toggle d-flex align-items-center py-3"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div>
                    <i
                      class="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size"
                    ></i>
                  </div>
                  <div>
                    <p class="text-muted mb-0 small">Select Location</p>
                    {{ selectedZone ? selectedZone.name : truncateWord(currentAddress) }}
                  </div>
                </a>
                <div
                  class="dropdown-menu p-0 drop-loc"
                  aria-labelledby="navbarDropdown"
                >
                  <div class="osahan-country">
                    <div class="search_location bg-primary p-3 text-right">
                      <div
                        class="input-group rounded shadow-sm overflow-hidden"
                      >
                        <div class="input-group-prepend">
                          <button
                            class="border-0 btn btn-outline-secondary text-dark bg-white btn-block"
                          >
                            <i class="feather-search"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          class="shadow-none border-0 form-control"
                          placeholder="Enter Your Location"
                        />
                      </div>
                    </div>

                    <div class="filter">
                      <h6 class="px-3 py-3 bg-light pb-1 m-0 border-bottom">
                        Choose your zone
                      </h6>
                      <div
                        class="custom-control border-bottom px-0 custom-radio"
                        v-for="(zone, i) in zones"
                        :key="i"
                      >
                        <input
                          type="radio"
                          :id="'customRadio' + zone.id"
                          name="location"
                          class="custom-control-input"
                          v-model="selectedZone"
                          :value="zone"
                          @click="changeZone(zone.id)"
                        />
                        <label
                          class="custom-control-label py-3 w-100 px-3"
                          :for="'customRadio' + zone.id"
                          >{{ zone.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- col.// -->
            <div class="col-8">
              <div class="d-flex align-items-center justify-content-end pr-5">
                <p
                  class="widget-header mr-4 text-dark m-none pt-3"
                  style="font-size: 12px"
                >
                  What would you like to eat?
                </p>
                <div class="mr-4">
                  <input
                    type="text"
                    placeholder="Search for meals"
                    style="border-radius: 20px"
                    class="form-control"
                   
                  />
                </div>
                <div class="dropdown mr-4 m-none" v-if="user == null">
                  <a
                    href="#"
                    class="dropdown-toggle text-dark py-3 d-block"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="feather-user h6 mr-2 mb-0"></i>
                    My Account
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <router-link class="dropdown-item" to="/profile"
                      >My Profile</router-link
                    >

                    <router-link class="dropdown-item" to="/orders"
                      >My Orders</router-link
                    >
                    <hr />
                    <router-link class="dropdown-item" to="/sign-in"
                      >SIGN IN/SIGN UP</router-link
                    >
                  </div>
                </div>
                <div class="dropdown mr-4 m-none" v-else>
                  <a
                    href="#"
                    class="dropdown-toggle text-dark py-3 d-block"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="feather-user h6 mr-2 mb-0"></i>
                    Hi {{ user.f_name }}
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <router-link class="dropdown-item" to="/profile"
                      >My Profile</router-link
                    >

                    <router-link class="dropdown-item" to="/orders"
                      >My Orders</router-link
                    >
                    <hr />
                    <a href="#" class="dropdown-item" style="cursor: 'pointer';" @click="Logout">
                      Logout
                    </a>
                  </div>
                </div>

                <div class="dropdown mr-4 m-none">
                  <router-link
                    to="/router-link"
                    class="dropdown-toggle text-dark py-3 d-block"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="feather-help-circle h6 mr-2 mb-0"></i>
                    Help
                  </router-link>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <router-link class="/help" href="profile.html"
                      >Help Center</router-link
                    >

                    <a class="dropdown-item" href="#">Place & track order</a>
                    <a class="dropdown-item" href="#">Order Cancellation</a>
                    <a class="dropdown-item" href="#">Refund & Return</a>
                    <a class="dropdown-item" href="#">Payment Terms</a>
                    <hr />
                    <button @click="openChat()" class="dropdown-item">Chat with us</button>
                  </div>
                </div>
                <!-- signin -->
                <router-link to="/cart" class="widget-header mr-4 text-dark">
                  <div class="icon d-flex align-items-center">
                    <i
                      class="feather-shopping-cart h6 mr-2 mb-0"
                      style="position: relative"
                    ></i>
                    <span
                      class="badge text-white"
                      style="
                        background-color: red;
                        position: absolute;
                        right: 105px;
                        bottom: 33px;
                      "
                      >{{ cartItems.length }}</span
                    >
                    <span>Cart</span>
                  </div>
                </router-link>

                <a class="toggle" href="#">
                  <span></span>
                </a>
              </div>
              <!-- widgets-wrap.// -->
            </div>
            <!-- col.// -->
          </div>
          <!-- row.// -->
        </div>
        <!-- container.// -->
      </section>
      <!-- header-main .// -->
    </header>
  </div>
</template>
<style scoped>
a:hover {
  color: #ffcc28 !important;
  transition: 1s;
}
</style>
<script>
import { EventBus } from "@/eventBus.js";
import axios from "axios";
export default {
  data() {
    return {
      user: null,
      cartItems: {},
      zones: {},
      selectedZone: null,
      searchQuery: '',
      currentAddress: ''
    };
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    Logout(){
       localStorage.removeItem('auth_info');
        localStorage.removeItem('token');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('cartItems');
        localStorage.clear()
       this.$router.push({name: 'Login'});
    },
    truncateWord(longSentence){
      const truncatedSentence = longSentence.slice(0, 20) + "…";
      return truncatedSentence;
    },
        // get current location
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
           this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
     async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBNDEeq0RAFJG6Iai_gTHIOsNE5_0EnV_c"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          console.log(data.results[0].formatted_address);
          console.log(data.results[0]);
          this.currentAddress = data.results[0].formatted_address;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    loadTawkToScript(){
      if (!window.Tawk_API) {
          var s1 = document.createElement("script");
          var s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6490b605cc26a871b02381ee/1h3ake2dg';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        }
    },
     openChat(){
       this.loadTawkToScript(); // Load the Tawk.to script
        if (window.Tawk_API) {
          window.Tawk_API.toggle();
        }
    },
    getZones() {
      this.$api
        .get(this.baseUrl("zone/list"))
        .then((res) => {
          // Assuming res.data is an array of zones
          if (Array.isArray(res.data) && res.data.length > 0) {
           
            this.zones = res.data;
            const zoneValue = JSON.parse(localStorage.getItem("zone"));

            if(zoneValue){
              this.selectedZone = zoneValue;
            }
            else{
              this.selectedZone = null
                // localStorage.setItem("zone", JSON.stringify(this.selectedZone));
            }
            

            // Get the value from the zone storage
            
            // If the value is empty (null or undefined), set the value in the zone storage
            // if (!zoneValue) {
            //   // this.selectedZone = res.data[0];
            //   localStorage.setItem("zone", JSON.stringify(this.selectedZone));
            // }
          
          } else {
            console.error("Invalid response data for zones:", res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching zones:", error);
          // You might want to handle the error or show a message to the user.
        });
    },
    changeZone(id) {
        const foundObject = this.zones.find(obj => obj.id === id);
        this.selectedZone = foundObject;
        localStorage.setItem("zone", JSON.stringify(this.selectedZone));
        location.reload();
    },
    handleEnterKey(){
      this.$router.push(`/search?query=${this.searchQuery}`)
    }
  },
  mounted() {
      // get present location
    this.locatorButtonPressed();

    this.user = this.authUser();
    this.getZones();
    this.displayCart();
    EventBus.$on("itemAddedToCart", () => {
      // Update cart item count
      this.displayCart();
    });
  },
  beforeDestroy() {
    // Clean up event listener when the component is destroyed
    EventBus.$off("itemAddedToCart");
  },
};
</script>
<template>
  <div>
    <!-- Modal (Deliver Now) -->
    <div
      class="modal fade"
      id="deliverNow"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deliverNowLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deliverNowLabel">
              Add Delivery Information
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label class="form-label">Delivery Area</label>
                  <div class="input-group mb-3">
                    <input
                      placeholder="Delivery Area"
                      type="text"
                      class="form-control"
                      v-model="orderDetails.road"
                    />
                    <div class="input-append">
                      <button class="btn btn-outline-secondary" type="button">
                        <i class="feather-map-pin"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Complete Address</label
                  ><input
                    placeholder="Complete Address e.g. house number, street name, landmark"
                    type="text"
                    class="form-control"
                    v-model="orderDetails.address"
                  />
                </div>


                 <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Delivery Instructions</label
                  ><input
                    placeholder="Delivery Instructions e.g. Opposite Gold Souk Mall"
                    type="text"
                    class="form-control"
                      v-model="orderDetails.order_note"
                  />
                </div>
            
              </div>
            </form>
          </div>
          <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn border-top btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- Deliver Later -->
    <div
      class="modal fade"
      id="deliverLater"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deliverLaterLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deliverLaterLabel">
              Add Delivery Information
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label class="form-label">Delivery Date</label>
                  <div class="input-group mb-3">
                    <input
                      placeholder="Select a delivery date and time"
                      type="datetime-local"
                      class="form-control"
                      v-model="orderDetails.schedule_at"
                    />
                    <!-- <div class="input-append">
                      <button class="btn btn-outline-secondary" type="button">
                        <i class="feather-map-pin"></i>
                      </button>
                    </div> -->
                  </div>
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Complete Address</label
                  ><input
                    placeholder="Complete Address e.g. house number, street name, landmark"
                    type="text"
                    class="form-control"
                    v-model="orderDetails.address"
                  />
                </div>

                 <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Delivery Instructions</label
                  ><input
                    placeholder="Delivery Instructions e.g. Opposite Gold Souk Mall"
                    type="text"
                    class="form-control"
                      v-model="orderDetails.order_note"
                  />
                </div>
           
              </div>
            </form>
          </div>
          <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn border-top btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start MEal plan -->
    <div
      class="modal fade"
      id="startMealPlan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="startMealPlanLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="startMealPlanLabel">
             Schedule Meal Plan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="">
              <div class="form-row">
                  <div class="col-md-12 form-group">
                  <label class="form-label">Schedule Period</label>
                  <div class="input-group mb-3">
                    <select
                      placeholder="Select a delivery date and time"
                      class="form-control"
                      v-model="subscription_type"
                    >
                    <option v-bind:key="index" v-for="(option, index) in options" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                   </select>
                
                  </div>
                </div>
               

              
                 <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Subscription Day(s)</label>
                    <div class="" style="display: flex; align-items:center; gap: 4px;">
                       <input
                      placeholder="Select a delivery date and time"
                      type="datetime-local"
                      class="form-control"
                      v-model="selectedDate"
                      style="flex: 60%"
                      :min="minDate"
                    />
                      <button @click="addDate()" class="btn btn-primary" type="button" id="button-addon2"><i class="feather-plus"></i> ADD</button>
                  </div>
                </div>

                   <div class="mb-3 ml-2">
                      <div class="mb-1" style="display:flex; align-items-center; gap: 10px;" v-for="(date, index) in selectedDates" :key="index">
                        <div>
                          {{index + 1}}. {{date}}
                        </div>
                        <div>
                          <i @click="removeDate(index)" style="cursor:pointer;" class="feather-delete"></i>
                        </div>
                      </div>
                   </div>
                
                <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Complete Address</label
                  ><input
                    placeholder="Complete Address e.g. house number, street name, landmark"
                    type="text"
                    class="form-control"
                    v-model="orderDetails.address"
                  />
                </div>

                  <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Delivery Instructions</label
                  ><input
                    placeholder="Delivery Instructions e.g. Opposite Gold Souk Mall"
                    type="text"
                    class="form-control"
                      v-model="orderDetails.order_note"
                  />
                </div>
              
              </div>
            </form>
          </div>
          <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn border-top btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none">
      <div class="bg-primary border-bottom p-3 d-flex align-items-center">
        <a
          class="toggle togglew toggle-2 hc-nav-trigger hc-nav-1"
          href="#"
          role="button"
          aria-controls="hc-nav-1"
          ><span></span
        ></a>
        <h4 class="fw-bold m-0 text-white">Checkout</h4>
      </div>
    </div>

    <div class="container position-relative">
      <div class="py-5 row g-4">
        <div class="col-md-8">
          <div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Contact Information</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-md-12 mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Full Name"
                          id=""
                          class="form-control"
                          v-model="orderDetails.full_name"
                        />
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Email Address"
                          id=""
                          class="form-control"
                          v-model="orderDetails.email"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Phone Number"
                          id=""
                          class="form-control"
                          v-model="orderDetails.phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Select Delivery Options</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-lg-6 mb-4">
                      <a
                        class="form-check position-relative border-custom-radio p-0"
                       
                      >
                        <div>
                          <div
                            class="p-3 bg-white rounded rounded-bottom-0 shadow-sm w-100 ordertype"
                            style="cursor:pointer;"
                            :class="{ active: activeTab === 0 }"
                             @click="activateTab(0)"
                              data-toggle="modal"
                            data-target="#deliverNow"
                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                          >
                            <div class="d-flex align-items-center mb-2">
                              <h6 class="mb-0">Deliver Now (20-30 min)</h6>

                              <p class="mb-0 badge text-bg-success ms-auto">
                                <!-- <i class="icofont-check-circled"></i> Default -->
                              </p>
                            </div>
                            <p class="small text-muted m-0">
                              Have your meal delivered immediately
                            </p>
                           
                            <!-- <p class="small text-muted m-0">
                              Redwood City, CA 94063
                            </p> -->
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a
                        class="form-check position-relative border-custom-radio p-0"
                       
                      >
                        <div>
                          <div
                            class="p-3 rounded rounded-bottom-0 bg-white shadow-sm w-100 ordertype"
                            :class="{ active: activeTab === 1 }"
                             @click="activateTab(1)"
                            style="cursor:pointer;"
                             data-toggle="modal"
                              data-target="#deliverLater"
                               data-bs-toggle="modal" data-bs-target="#exampleModal"
                          >
                            <div class="d-flex align-items-center mb-2">
                              <h6 class="mb-0">Deliver Later</h6>
                              <p class="mb-0 badge text-bg-light ms-auto">
                                <!-- <i class="icofont-check-circled"></i> Select -->
                              </p>
                            </div>
                            <p class="small text-muted m-0">
                              Have your meal delivered on a specific date
                            </p>
                              
                            <!-- <p class="small text-muted m-0">
                              Punjab 141002, India
                            </p> -->
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <div
                        class="form-check position-relative border-custom-radio p-0"
                          style="cursor:pointer;"
                      >
                        <div>
                          <div
                            class="p-3 rounded rounded-bottom-0 bg-white shadow-sm w-100 ordertype"
                            :class="{ active: activeTab === 2 }"
                             @click="activateTab(2)"
                              data-toggle="modal"
                                  data-target="#startMealPlan"
                                  style="cursor: pointer"
                                href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                          >
                            <div class="d-flex align-items-center mb-2">
                              <h6 class="mb-0">Schedule Meal</h6>
                              <p class="mb-0 badge text-bg-light ms-auto">
                                <!-- <i class="icofont-check-circled"></i> Select -->
                              </p>
                            </div>
                            <p class="small text-muted m-0">
                              Schedule a meal Plan i.e Daily, Weekly or
                              Monthly
                            </p>
                            
                            <!-- <p class="small text-muted m-0">
                              Punjab 141002, India
                            </p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Additional Information</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-md-12 mb-3">
                      <p>Order notes(Optional)</p>
                      <div class="input-group">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control"
                          placeholder="Notes about your order"
                          v-model="orderDetails.delivery_instruction"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="osahan-cart-item rounded rounded overflow-hidden bg-white "
          >
            <vue-element-loading
              :active="loading"
              color="#FF6700"
              text="Please wait"
              spinner="line-wave"
            />

            <div class="p-3 border-bottom">
               <h6 class="mb-1 fw-bold">Order Summary</h6>
            
              <div class="d-flex osahan-cart-item-profile bg-white mt-3">
                <img alt="osahan" :src="
                          'https://backend.seid.in/storage/product/' + cartItems[0].product.image" style="width: 41px; height:41px;" class="mr-3 rounded-circle img-fluid">
                <div class="d-flex flex-column">
                <h6 class="mb-1 fw-bold">{{cartItems[0].product.restaurant_name}}</h6>
                <p class="mb-0 small text-muted"><i class="feather-info"></i> {{cartItems[0].product.description}}</p>
                </div>
                </div>
              </div>
             </div>

    
            <div class="bg-white">
              <div
                class="gold-members  px-3 py-2 border-bottom"
                v-for="(c, i) in cartItems"
                :key="i"
              > 
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="me-2 mr-2 text-success">·</div>
                    <div class="media-body">
                      <p class="m-0">{{ c.product.name }}</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="count-number float-end"
                      >
                      <!-- <button
                        type="button"
                        class="btn-sm left dec btn btn-outline-secondary"
                      >
                        <i class="feather-minus"></i></button
                      > -->
                      <input
                        class="count-number-input small"
                        type="text"
                        readonly=""
                        :value="`(${c.quantity})`"
                       />
                        <!-- <button
                        type="button"
                        class="btn-sm right inc btn btn-outline-secondary"
                      >
                        <i class="feather-plus"></i></button
                      > -->
                    </span>
                    <p class="text-gray mb-0 float-end ml-1 text-muted small">
                      ₦ {{ c.product.price * c.quantity }}
                    </p>
                  </div>
                </div>

                  <div class="gold-members ml-4"
                    v-for="(c,i) in c.addons"
                    :key="i"
                    >
                <div class="d-flex align-items-center justify-content-between mt-2">
                   <div class="d-flex align-items-center">
                  <div class="me-2 mr-2 text-success">·</div>
                  <div class="media-body">
                    <p class="m-0">{{c.addon}}</p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                   <span class="count-number float-end"
                      >
                      <!-- <button
                        type="button"
                        class="btn-sm left dec btn btn-outline-secondary"
                      >
                        <i class="feather-minus"></i></button
                      > -->
                      <input
                        class="count-number-input small"
                        type="text"
                        readonly=""
                        :value="`(${c.quantity})`"
                       />
                        <!-- <button
                        type="button"
                        class="btn-sm right inc btn btn-outline-secondary"
                      >
                        <i class="feather-plus"></i></button
                      > -->
                    </span>
                  <p class="text-gray mb-0 float-end ml-1 text-muted small">
                    ₦ {{c.price}}
                  </p>
                </div>
                  </div>
                  </div>

              </div>
            </div>
             
            <div class="bg-white p-3 py-3 border-bottom clearfix">
              <div class="input-group input-group-sm mb-2">
              <input v-model="coupon" placeholder="Enter promo code" type="text" class="form-control" style="height: auto;">
              <button :disabled="loading" @click="applyCoupon" class="btn btn-primary" type="button" id="button-addon2"><i class="feather-percent"></i> APPLY</button>
              </div>
            </div>
            <div class="bg-white p-3 clearfix border-bottom">
              <p class="mb-1">
                Item Total
                <span class="float-end text-dark">₦ {{ totalAmount }}</span>
              </p>
              <p class="mb-1">
                VAT
                <span class="float-end text-dark">₦ {{ totalTax }}</span>
              </p>
              <p class="mb-1">
                Delivery Fee<span class="text-info ml-1"
                  ><i class="feather-info"></i></span
                ><span class="float-end text-dark"
                  >₦ {{ zone?.minimum_shipping_charge }}</span
                >
              </p>
               <p class="mb-1" v-if="selectedDates.length > 0">
                Delivery Days<span class="float-end text-dark"
                  > {{ deliveryDays }} day(s)</span
                >
              </p>
              <p class="mb-1 text-success">
                Total Discount<span class="float-end text-success">₦ {{discountAmount}}</span>
              </p>
              <hr />
              <h6 class="fw-bold mb-0">
                TO PAY
                <span class="float-end"
                  >₦{{
                    ((totalAmount + totalTax + zone?.minimum_shipping_charge) * deliveryDays) - discountAmount
                  }}</span
                >
              </h6>
            </div>
            <div class="p-3">
              <button :disabled="loading" class="btn btn-primary w-100 btn-lg" @click="createOrder">
                PAY ₦{{ ((totalAmount + totalTax + zone?.minimum_shipping_charge) * deliveryDays) - discountAmount
                }}<i class="feather-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>  
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  data() {
    return {
        minDate: new Date().toISOString().split('.')[0], // Current date and time in ISO format
       activeTab: 0,
      showModal: false,
      quantity: 1,
      loading: false,
      cartItems: {},
      addOns: [],
      user: {},
      totalAmount: "",
      totalTax: "",
      zone: {},
      subscription_type: 'daily',
      subscription_end_at: "",
      subscription_start_at: "",
      selectedDate: "",
      selectedDates: [],
      deliveryDays: 1,
       options: [
          { text: 'Daily', value: 'daily' }
       ],
         coupon: "",
        discountAmount: 0,
      orderDetails: {
        l_name: "",
        full_name: "",
        f_name: "",
        coupon_discount_amount: 0,
        coupon_discount_title: null,
        order_note: "",
        order_type: "",
        schedule_at: "",
        coupon_code: null,
        discount_amount: 0,
        schedule_at: null,
        latitude: "23.76393122685946",
        longitude: "90.3514959774026",
        contact_person_name: "Ass Ass",
        contact_person_number: "+2*****499",
        address_type: "others",
        address: "",
        road: "",
        house: "972",
        floor: "2",
        dm_tips: "",
        subscription_order: "0",
        subscription_type: "",
        subscription_days: "[]",
        subscription_quantity: "1",
        subscription_start_at: "",
        subscription_end_at: "",
        unavailable_item_note: "",
        delivery_instruction: "",
        cutlery: "0",
        status: "plan",
        dates: [],
      },
    };
  },
  components: {
    VueElementLoading,
    axios,
    DatePicker
  },
  methods: {
    activateTab(index) {
      this.activeTab = index;
    },
     disablePastDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to start of the day

      // Check if the provided date is less than today's date
      return date < today;
    },
    applyCoupon(){
         this.loading = true;
        const zoneValue = JSON.parse(localStorage.getItem("zone"));
       let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let res = {
        coupon: this.coupon,
        restaurant_id: cartItems.length ? cartItems[0].product.restaurant_id: "",
        zoneId: zoneValue.id
      }
      this.$store.dispatch("applyCoupon",res)
      .then((res)=>{
           this.loading = false
            this.$toast.success("Coupon code applied!", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          this.discountAmount = res.discount
          localStorage.setItem("discountAmount", res.discount);   
      })
      .catch((error)=>{
           this.loading = false
        if(error.response.status === 404){
            this.$toast.error("There is no coupon available", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
         }
         else{
            this.$toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });  
         }
      })
    },
    addDate(){
      if(this.selectedDate){
          if (
                !this.selectedDates.some(date => date === this.selectedDate)
              ) {
              this.selectedDates.push(this.selectedDate);
              this.deliveryDays = this.selectedDates.length
              }
      }
      else{
          this.$toast.error('Please select a valid date', {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
      }
     
    },
     removeDate(index) {
      this.selectedDates.splice(index, 1);
      if(this.selectedDates.length == 0){
        this.deliveryDays = 1
      }
      else{
        this.deliveryDays = this.selectedDates.length
      }
    },
    openModal() {
      this.showModal = true;
    },
    checkout() {
      this.$router.push("/checkout");
    },
    closeModal() {
      this.showModal = false;
    },
    add() {
      this.quantity++;
    },
    minus() {
      if (this.quantity !== 1) {
        this.quantity--;
      }
    },
     formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      return `${day}/${month}/${year}`;
    },
    formatDateTime(inputDate) {
        const [datePart, timePart] = inputDate.split('T');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
        return `${year}-${month}-${day} ${hours}:${minutes}:00`;
      
    },

    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
      this.calculateCartTotal(cartItems);
      this.calculateTax(cartItems);
    },
    createOrder() {
      this.loading = true;
      let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
      let cartItems = this.cartItems;
      let newCartItems = [];
      // console.log(cartItems);
      for (let index = 0; index < cartItems.length; index++) {
        let addOns = cartItems[index].addons;
        let addOnsArray = [];
        let addOnsArrayId = [];
        let addOnsArrayQtys = [];
        addOns.forEach((element) => {
          addOnsArray.push({
            id: element.id, //you need to save the addon id to localstorage
            name: element.addon,
            price: element.price,
          });
          addOnsArrayId.push(element.id);
          addOnsArrayQtys.push(element.quantity);
        });
        let newArray = {
          food_id: cartItems[index].product.id,
          price: cartItems[index].product.price,
          add_ons: addOnsArray,
          add_on_ids: addOnsArrayId,
          add_on_qtys: addOnsArrayQtys,
          quantity: cartItems[index].quantity,
        };
        newCartItems.push(newArray);
      }

      if(this.activeTab === 2){
        this.orderDetails.cart = newCartItems;
          this.orderDetails.order_amount = ((this.totalAmount  + this.totalTax + this.zone.minimum_shipping_charge) * this.deliveryDays) - this.discountAmount;
          this.orderDetails.contact_person_name =
          this.orderDetails.l_name + " " + this.orderDetails.f_name;
          this.orderDetails.contact_person_number = this.orderDetails.phone;
          this.orderDetails.payment_method = "digital_payment";
          this.orderDetails.coupon_discount_amount = this.discountAmount;
          this.orderDetails.coupon_code = this.coupon;
          this.orderDetails.discount_amount = this.discountAmount;
          this.orderDetails.order_type = "delivery";
          this.orderDetails.address = this.orderDetails.address;
          this.orderDetails.road = this.orderDetails.road;
          this.orderDetails.delivery_instruction = this.orderDetails.delivery_instruction;
          this.orderDetails.distance = 661.877;
          this.orderDetails.longitude = "90.3514959774026";
          this.orderDetails.latitude = "90.3514959774026";
          this.orderDetails.dm_tips = 0;
          this.orderDetails.order_note = this.orderDetails.order_note;
          this.orderDetails.schedule_at = this.orderDetails.schedule_at;
          this.orderDetails.restaurant_id = this.cartItems[0].product.restaurant_id;
          this.orderDetails.subscription_type = this.subscription_type;
          this.orderDetails.dates = this.selectedDates;
          this.orderDetails.status = "plan";

            // console.log(this.orderDetails);
            // return false;

               axios
            .post(
              "https://backend.seid.in/api/v1/customer/order/place-multiple-order",
              this.orderDetails,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
               this.loading = false;
                let totalAmt = ((this.totalAmount  + this.totalTax + this.zone.minimum_shipping_charge) * this.deliveryDays) - this.discountAmount
                this.payWithPaystackMultiple(res.data.order_id, res.data.total_ammount)
            })
             .catch((error)=>{
               this.loading = false;
               if (error.response.status == 403) {
            this.$toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            return;
          }
          else{
            this.$toast.error(error.response.data.errors[0].message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
         
        })
      }
      else{
           this.orderDetails.cart = newCartItems;
            this.orderDetails.order_amount = ((this.totalAmount  + this.totalTax + this.zone.minimum_shipping_charge) * this.deliveryDays) - this.discountAmount;
            this.orderDetails.contact_person_name =
            this.orderDetails.l_name + " " + this.orderDetails.f_name;
            this.orderDetails.contact_person_number = this.orderDetails.phone;
            this.orderDetails.coupon_discount_amount = this.discountAmount;
             this.orderDetails.discount_amount = this.discountAmount;
              this.orderDetails.coupon_code = this.coupon;
            this.orderDetails.payment_method = "digital_payment";
            this.orderDetails.order_type = "delivery";
            this.orderDetails.address = this.orderDetails.address;
            this.orderDetails.road = this.orderDetails.road;
            this.orderDetails.delivery_instruction = this.orderDetails.delivery_instruction;
            this.orderDetails.distance = 661.877;
            this.orderDetails.longitude = "90.3514959774026";
            this.orderDetails.latitude = "90.3514959774026";
            this.orderDetails.dm_tips = 0;
            this.orderDetails.order_note = this.orderDetails.order_note;
            this.orderDetails.schedule_at = this.orderDetails.schedule_at;
            this.orderDetails.restaurant_id = this.cartItems[0].product.restaurant_id;

            // console.log(this.orderDetails);
          // return false;
          axios
            .post(
              "https://backend.seid.in/api/v1/customer/order/place",
              this.orderDetails,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
               this.loading = false;
                let totalAmt = ((this.totalAmount  + this.totalTax + this.zone.minimum_shipping_charge) * this.deliveryDays) - this.discountAmount
                this.payWithPaystack(res.data.order_id, res.data.total_ammount)
            })
             .catch((error)=>{
               this.loading = false;
                if (error.response.status == 403) {
                this.$toast.error(error.response.data.errors[0].message, {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                return;
              }
              else{
                this.$toast.error(error.response.data.errors[0].message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
        })
    

      }

     

    
    },
    generateRandomText(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomText = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomText += characters.charAt(randomIndex);
      }

      return randomText;
    },
    payWithPaystack(order_id, amount) {
      var handler = PaystackPop.setup({
        key: "pk_test_be5fdcee5bc309d31327da3389210351795d3ea7", // Replace with your public key

        email: this.user.email,

        amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit

        currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars

        ref: order_id + this.generateRandomText(5), // Replace with a reference you generated

        callback: (response) => {
          localStorage.removeItem("cartItems");
          localStorage.setItem("discountAmount", 0);
          localStorage.setItem("couponCode", "");
          var reference = response.reference;

          let payload = {
            order_id: order_id,
            transaction_reference: response.reference,
          };
          
          // Make an AJAX call to your server with the reference to verify the transaction
           let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
          axios
            .post(
              "https://backend.seid.in/api/v1/test-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
            })
             .catch(()=>{
               this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
              console.log('an error occured')
            });
        },

        onClose: function () {
          alert("Transaction was not completed, window closed.");
        },
      });

      handler.openIframe();
    },

     payWithPaystackMultiple(order_id, amount) {
      var handler = PaystackPop.setup({
        key: "pk_test_be5fdcee5bc309d31327da3389210351795d3ea7", // Replace with your public key

        email: this.user.email,

        amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit

        currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars

        ref: order_id + this.generateRandomText(5), // Replace with a reference you generated

        callback: (response) => {
          localStorage.removeItem("cartItems");
          localStorage.setItem("discountAmount", 0);
          localStorage.setItem("couponCode", "");
          var reference = response.reference;

          let payload = {
            sub_id: order_id,
            transaction_reference: response.reference,
          };
          
          // Make an AJAX call to your server with the reference to verify the transaction
           let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
          axios
            .post(
              "https://backend.seid.in/api/v1/test-multiple-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
            })
             .catch(()=>{
               this.$router.push(`/order-successful?trxref=${payload.transaction_reference}`);
              console.log('an error occured')
            });
        },

        onClose: function () {
          alert("Transaction was not completed, window closed.");
        },
      });

      handler.openIframe();
    },

    handleVerifyPayment(payload){
        let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
      axios
            .post(
              "https://backend.seid.in/api/v1/test-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              this.$router.push(`/order-successful?reference=${payload.transaction_reference}`);
            })
    },
    logOut() {
      let auth_user =
        localStorage.getItem("auth_info") != null
          ? localStorage.setItem("checkout", "true")
          : this.$router.push("/sign-in");
    },
    calculateCartTotal(cartItems) {
      let totalAmount = 0;

      // Parse the cartItems JSON string into an array
      const items = cartItems;

      // Loop through each item in the cart
      items.forEach((item) => {
        const { quantity, product } = item;
        const { price } = product;
      
        var extras = item.addons
        const extrasTotalPrice = extras.reduce((total, extra) => total + (extra.price * extra.quantity), 0);

        // Multiply the price by the quantity and add it to the total amount
        totalAmount += price * quantity + extrasTotalPrice;
      });
      this.totalAmount = totalAmount;
    },
    calculateTax(cartItems) {
      let totalPrice = 0;

      // Parse the cartItems JSON string into an array
      const items = cartItems;
      let itemPrice = 0
      // Loop through each item in the cart
      items.forEach((item) => {
        const { product, quantity } = item;
        const { price } = product;

         var extras = item.addons
        const extrasTotalPrice = extras.reduce((total, extra) => total + (extra.price * extra.quantity), 0);
        console.log(extrasTotalPrice)
        
        const tax = product.tax;
      
        // Calculate the price of the current item and its addons
     
         totalPrice += price * quantity + extrasTotalPrice;
          itemPrice = (tax / 100) * totalPrice;

        // Add the   item price to the total price
        // totalPrice = itemPrice;
      });

      this.totalTax = itemPrice;
    },
    getZone() {
      const zoneValue = JSON.parse(localStorage.getItem("zone"));
      this.zone = zoneValue;
    },
    getDiscountValue(){
       let discountAmount =  localStorage.getItem("discountAmount") || 0;
       let couponCode =  localStorage.getItem("couponCode") || "";
       this.coupon = couponCode
       this.discountAmount = discountAmount

    }
  },
   watch: {
    // selectedDate(newDate) {
    //   console.log(newDate)
    //     this.selectedDates.push(newDate);
    // },
  },

  mounted() {
    this.logOut();
    this.displayCart();
    this.getZone();
    this.orderDetails = this.authUser();
    this.user = this.authUser();
    this.getDiscountValue()
    this.orderDetails.full_name =
      this.orderDetails.l_name + " " + this.orderDetails.f_name;
  },
};
</script>
<style scoped>
.ordertype{
  border: 1px solid #ccc;
}
.ordertype.active{
 border: 2px solid #d92662;
}
.mx-datepicker{
  flex: 50%;
}
.mx-datepicker-range {
    width: 100%;
}
</style>
<template>
  <div>
   
    <!-- Start MEal plan -->
    <div
      class="modal fade"
      id="startMealPlan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="startMealPlanLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="startMealPlanLabel">
             Start Plan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="">
              <div class="form-row">
                
                 <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Start Date</label>
                    <div class="">
                       <input
                      placeholder="Select a delivery date and time"
                      type="datetime-local"
                      class="form-control"
                      v-model="selectedDate"
                      :min="minDate"
                    />
                  </div>
                </div>

                <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Complete Address</label
                  ><input
                    placeholder="Complete Address e.g. house number, street name, landmark"
                    type="text"
                    class="form-control"
                    v-model="orderDetails.address"
                  />
                </div>

                  <!-- <div class="col-md-12 form-group mb-3">
                  <label class="form-label">Delivery Instructions</label
                  ><input
                    placeholder="Delivery Instructions e.g. Opposite Gold Souk Mall"
                    type="text"
                    class="form-control"
                      v-model="orderDetails.delivery_instruction"
                  />
                </div> -->
              
              </div>
            </form>
          </div>
          <div class="modal-footer p-0 border-0">
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn border-top btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
            <div class="col-6 m-0 p-0">
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none">
      <div class="bg-primary border-bottom p-3 d-flex align-items-center">
        <a
          class="toggle togglew toggle-2 hc-nav-trigger hc-nav-1"
          href="#"
          role="button"
          aria-controls="hc-nav-1"
          ><span></span
        ></a>
        <h4 class="fw-bold m-0 text-white">Checkout</h4>
      </div>
    </div>

    <div class="container position-relative">
      <div class="py-5 row g-4">
        <div class="col-md-8">
          <div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Contact Information</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-md-12 mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Full Name"
                          id=""
                          class="form-control"
                          v-model="orderDetails.full_name"
                        />
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Email Address"
                          id=""
                          class="form-control"
                          v-model="orderDetails.email"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="input-group">
                        <input
                          type="text"
                          name=""
                          placeholder="Enter Your Phone Number"
                          id=""
                          class="form-control"
                          v-model="orderDetails.phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Select Delivery Options</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-lg-6">
                      <div
                        class="form-check position-relative border-custom-radio p-0"
                          style="cursor:pointer;"
                      >
                        <div>
                          <div
                            class="p-3 rounded rounded-bottom-0 bg-white shadow-sm w-100 ordertype"
                            :class="{ active: activeTab === 0 }"
                             @click="activateTab(0)"
                              data-toggle="modal"
                                  data-target="#startMealPlan"
                                  style="cursor: pointer"
                                href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                          >
                            <div class="d-flex align-items-center mb-2">
                              <h6 class="mb-0">Start Plan</h6>
                              <p class="mb-0 badge text-bg-light ms-auto">
                                <!-- <i class="icofont-check-circled"></i> Select -->
                              </p>
                            </div>
                            <p class="small text-muted m-0">
                              Select your preference and a start date for this plan
                            </p>
                            
                            <!-- <p class="small text-muted m-0">
                              Punjab 141002, India
                            </p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="osahan-cart-item mb-3 rounded shadow-sm bg-white overflow-hidden"
            >
              <div class="osahan-cart-item-profile bg-white p-3">
                <div class="d-flex flex-column">
                  <h6 class="mb-3 fw-bold">Additional Information</h6>
                  <div class="row g-4 mb-3">
                    <div class="col-md-12 mb-3">
                      <p>Order notes(Optional)</p>
                      <div class="input-group">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control"
                          placeholder="Notes about your order"
                          v-model="order_note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="osahan-cart-item rounded rounded overflow-hidden bg-white "
          >
            <vue-element-loading
              :active="loading"
              color="#FF6700"
              text="Please wait"
              spinner="line-wave"
            />

            <div class="p-3 border-bottom">
               <h6 class="mb-1 fw-bold">Plan Summary</h6>
            
              <div class="d-flex osahan-cart-item-profile bg-white mt-3">
                <img alt="osahan" src="../../../public/assets/img/logo.png" style="width: 41px; height:41px;" class="mr-3 rounded-circle img-fluid">
                <div class="d-flex flex-column">
                <h6 class="mb-1 fw-bold">{{planSelected.name}}</h6>
                <p class="mb-0 small text-muted">
                {{planSelected.description}}</p>
                </div>
                </div>
              </div>
             </div>

    
            <div class="bg-white">
              <div
                class="gold-members  px-3 py-2 border-bottom"
                v-for="(c, i) in planSelected.benefits"
                :key="i"
              > 
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <!-- <div class="me-2 mr-2 text-success">·</div> -->
                     <span class="feather-check text-success"></span>
                    <div class="media-body ml-2">
                      <p class="m-0">{{c.benefit}}</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    
                  </div>
                </div>

              </div>
            </div>
             
            <div class="bg-white p-3 py-3 border-bottom clearfix">
              <div class="input-group input-group-sm mb-2">
              <input v-model="coupon" placeholder="Enter promo code" type="text" class="form-control" style="height: auto;">
              <button :disabled="loading" class="btn btn-primary" type="button" id="button-addon2"><i class="feather-percent"></i> APPLY</button>
              </div>
            </div>
            <div class="bg-white p-3 clearfix border-bottom">
              <p class="mb-1">
                Item Total
                <span class="float-end text-dark">₦ {{ planSelected.price }}</span>
              </p>
  
              <!-- <p class="mb-1">
                Delivery Fee<span class="text-info ml-1"
                  ><i class="feather-info"></i></span
                ><span class="float-end text-dark"
                  >₦ 0</span
                >
              </p> -->
               <p class="mb-1" v-if='selectedDate !== ""'>
                Start Date<span class="float-end text-dark"
                  > {{ selectedDate }} </span
                >
              </p>
              <!-- <p class="mb-1 text-success">
                Total Discount<span class="float-end text-success">₦ {{discountAmount}}</span>
              </p> -->
              <hr />
              <h6 class="fw-bold mb-0">
                TO PAY
                <span class="float-end"
                  >₦{{
                     planSelected.price }}</span
                >
              </h6>
            </div>
            <div class="p-3">
              <button :disabled="loading" class="btn btn-primary w-100 btn-lg" @click="createOrder">
                PAY ₦{{  planSelected.price}}<i class="feather-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>  
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  data() {
    return {
      minDate: new Date().toISOString().split('.')[0], // Current date and time in ISO format
      activeTab: 0,
      showModal: false,
      quantity: 1,
      loading: false,
      cartItems: {},
      addOns: [],
      user: {},
      totalAmount: "",
      totalTax: "",
      zone: {},
      subscription_start_at: "",
      selectedDate: "",
      selectedDates: [],
      deliveryDays: 1,
       options: [
          { text: 'Daily', value: 'daily' }
       ],
         coupon: "",
        discountAmount: 0,
        order_note: "",
       orderDetails: {
        l_name: "",
        full_name: "",
        f_name: "",
        phone: "",
        address_type: "others",
        address: "",
        delivery_instruction: ""
      },
    };
  },
  components: {
    VueElementLoading,
    axios,
    DatePicker
  },
  methods: {
    activateTab(index) {
      this.activeTab = index;
    },
     disablePastDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to start of the day

      // Check if the provided date is less than today's date
      return date < today;
    },
    applyCoupon(){
         this.loading = true;
        const zoneValue = JSON.parse(localStorage.getItem("zone"));
       let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let res = {
        coupon: this.coupon,
        restaurant_id: cartItems.length ? cartItems[0].product.restaurant_id: "",
        zoneId: zoneValue.id
      }
      this.$store.dispatch("applyCoupon",res)
      .then((res)=>{
           this.loading = false
            this.$toast.success("Coupon code applied!", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          this.discountAmount = res.discount
          localStorage.setItem("discountAmount", res.discount);   
      })
      .catch((error)=>{
           this.loading = false
        if(error.response.status === 404){
            this.$toast.error("There is no coupon available", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
         }
         else{
            this.$toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });  
         }
      })
    },
    addDate(){
      if(this.selectedDate){
          if (
                !this.selectedDates.some(date => date === this.selectedDate)
              ) {
              this.selectedDates.push(this.selectedDate);
              this.deliveryDays = this.selectedDates.length
              }
      }
      else{
          this.$toast.error('Please select a valid date', {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
      }
     
    },
     removeDate(index) {
      this.selectedDates.splice(index, 1);
      if(this.selectedDates.length == 0){
        this.deliveryDays = 1
      }
      else{
        this.deliveryDays = this.selectedDates.length
      }
    },
    openModal() {
      this.showModal = true;
    },
    checkout() {
      this.$router.push("/checkout");
    },
    closeModal() {
      this.showModal = false;
    },
    add() {
      this.quantity++;
    },
    minus() {
      if (this.quantity !== 1) {
        this.quantity--;
      }
    },
     formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      return `${day}/${month}/${year}`;
    },
    formatDateTime(inputDate) {
        const [datePart, timePart] = inputDate.split('T');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
        return `${year}-${month}-${day} ${hours}:${minutes}:00`;
      
    },

    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    createOrder() {
      // check if start date has been selected
      if(this.selectedDate === ""){
          this.$toast.error("You need to select a start date!", {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
          });
      }
      else{
      this.loading = true;
      let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
     
      // console.log(cartItems);
       const zoneValue = JSON.parse(localStorage.getItem("zone"));
    
          axios
            .post(
              "https://backend.seid.in/api/v1/customer/order/subscribe-init",
              {
                subscription_plan_id: this.planSelected.id,
                start_date: this.selectedDate,
                address:  this.orderDetails.address,
                note: this.order_note

              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                  zoneId: zoneValue.id === 1 ? "[1]" : "[2]"
                },
              }
            )
            .then((res) => {
               this.loading = false;
                this.payWithPaystack(this.planSelected.id, this.planSelected.price)
            })
             .catch((error)=>{
               this.loading = false;
                if (error.response.status == 403) {
                this.$toast.error(error.response.data.errors[0].message, {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
                return;
              }
              else{
                this.$toast.error(error.response.data.errors.address[0], {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
        })
      }  
    },
    generateRandomText(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomText = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomText += characters.charAt(randomIndex);
      }

      return randomText;
    },
    payWithPaystack(order_id, amount) {
      var handler = PaystackPop.setup({
        key: "pk_test_be5fdcee5bc309d31327da3389210351795d3ea7", // Replace with your public key

        email: this.user.email,

        amount: amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit

        currency: "NGN", // Use GHS for Ghana Cedis or USD for US Dollars

        ref: order_id + this.generateRandomText(5), // Replace with a reference you generated

        callback: (response) => {
          localStorage.setItem("discountAmount", 0);
          localStorage.setItem("couponCode", "");

          let payload = {
            subscription_plan_id: order_id,
            transaction_reference: response.reference,
          };
          
          // Make an AJAX call to your server with the reference to verify the transaction
           let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
           const zoneValue = JSON.parse(localStorage.getItem("zone"));

          axios
            .post(
              "https://backend.seid.in/api/v1/test-subscription-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                  zoneId: zoneValue.id === 1 ? "[1]" : "[2]"
                },
              }
            )
            .then((res) => {
              this.$router.push(`/subscription-successful?trxref=${payload.transaction_reference}`);
            })
             .catch(()=>{
               this.$router.push(`/subscription-successful?trxref=${payload.transaction_reference}`);
            });
        },

        onClose: function () {
          alert("Transaction was not completed, window closed.");
        },
      });

      handler.openIframe();
    },

    handleVerifyPayment(payload){
        let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
      axios
            .post(
              "https://backend.seid.in/api/v1/test-payment-verify",
              payload,
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              this.$router.push(`/order-successful?reference=${payload.transaction_reference}`);
            })
    },
    logOut() {
      let auth_user =
        localStorage.getItem("auth_info") != null
          ? localStorage.setItem("plan-checkout", "true")
          : this.$router.push("/sign-in");
    },

    getZone() {
      const zoneValue = JSON.parse(localStorage.getItem("zone"));
      this.zone = zoneValue;
    },
    getDiscountValue(){
       let discountAmount =  localStorage.getItem("discountAmount") || 0;
       let couponCode =  localStorage.getItem("couponCode") || "";
       this.coupon = couponCode
       this.discountAmount = discountAmount
    },
    getPlan(){
      let plan = JSON.parse(localStorage.getItem("plan"));
      this.planSelected = plan
    }
  },
  mounted() {
    this.logOut();
    this.displayCart();
    this.getZone();
    this.orderDetails = this.authUser();
    this.user = this.authUser();
    this.getDiscountValue()
    this.getPlan()
    this.orderDetails.full_name =
      this.orderDetails.l_name + " " + this.orderDetails.f_name;
  },
};
</script>
<style scoped>
.ordertype{
  border: 1px solid #ccc;
}
.ordertype.active{
 border: 2px solid #d92662;
}
.mx-datepicker{
  flex: 50%;
}
.mx-datepicker-range {
    width: 100%;
}
</style>
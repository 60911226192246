export default {
    data: () => {
        return {
        }
    },
  methods: {
    baseUrl(endUrl) { 
        return process.env.VUE_APP_BASE_URL+endUrl
      },
      authUser() {
let auth_user = localStorage.getItem('auth_info') != null ? JSON.parse(localStorage.getItem('auth_info')).auth_user :null;
        return auth_user;
      },
      logoutUser() {
        this.$toast.error('Login expired, please login again!', {
          position: 'top-center',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
        localStorage.removeItem('auth_info');
        localStorage.removeItem('token');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('cartItems');
        localStorage.clear()
        return this.$router.push({name: 'Login'});
      }
  }
};

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axiosConfig from './axios-config';
import authMixin from './mixins/auth';
import Toast from 'vue-toastification';
import store from './store';
// Import the CSS or use your own!
import "./assets/notifications.css";
Vue.prototype.$api=axiosConfig;

Vue.use(Toast);
Vue.mixin(authMixin);
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  let isLoggedIn = localStorage.getItem('auth_info') != null ?  JSON.parse(localStorage.getItem('auth_info')).auth_token :null;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //  Route requires AUth? Check if logged in. If not, redirect to login page
    if(!isLoggedIn || isLoggedIn==null) {
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    //  Route requires AUth? Check if logged in. If not, redirect to login page
    if(isLoggedIn) {
      next({
        name: 'Home'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <div class="first-page">
      <header>
        <div class="container">
          <div class="d-md-block d-none logo">
            <img src="../../../public/assets/img/logo.png" alt="" width="7%" />
          </div>
          <div class="d-md-none d-block logo">
            <img src="../../../public/assets/img/logo.png" alt="" width="20%" />
          </div>
        </div>
      </header>
      <main>
        <div class="container">
          <div class="inner-content pt-5">
            <h1 class="text-center pt-5 pt-md-0">
              <div class="pt-5 pt-md-0">
                <span class="typed-text"></span
                ><span class="cursor blink">&nbsp;</span>
              </div>
            </h1>
            <div class="text-center pt-4">
              <div class="input-group text-center desk-inp">
                <input
                  type="text"
                   v-model="searchQuery"
                    @keyup.enter="handleEnterKey"
                  class="pl-4 form-control mx-auto change"
                  placeholder="Search for meals..."
                />
                <div class="input-group-append">
                  <i
                    class="input-group-text bi bi-search pl-3 pr-4"
                    style="
                      border-radius: 0px 30px 30px 0px !important;
                      background-color: white;
                    "
                  ></i>
                </div>
              </div>
              <div class="input-group text-center d-md-none mob-inp">
                <input
                  type="text"
                    v-model="searchQuery"
                    @keyup.enter="handleEnterKey"
                  class="pl-4 form-control mx-auto change"
                  placeholder="Search for meals..."
                />
                <div class="input-group-append">
                  <i
                    class="input-group-text bi bi-search pl-3 pr-4"
                    style="
                      border-radius: 0px 30px 30px 0px !important;
                      background-color: white;
                    "
                  ></i>
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-md-6 col-6 pt-5 pt-md-0">
                  <router-link to="/meals">
                    <button class="btn bg-primary order-now float-md-right">
                      Order Now
                    </button>
                  </router-link>
                </div>
                <div class="col-md-6 col-6 pt-5 pt-md-0">
                  <a href="#subscribe">
                    <button class="btn bg-primary register-now float-md-left">
                      Subscribe Now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="second-page py-5">
      <div class="container">
        <div class="second-section">
          <div class="row pt-4">
            <div class="col-md-6">
              <h1 class="just-relax d-md-block d-none">
                JUST RELAX AT HOME <br />
                WE WILL SERVE YOU
              </h1>
              <h1 class="just-relax2 d-md-none d-block pb-5">
                JUST RELAX AT HOME WE WILL SERVE YOU
              </h1>
              <div class="img pt-3 d-md-block d-none">
                <img
                  src="../../../public/assets/img/img1-removebg-preview.png"
                  alt=""
                  class="iamgo"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 text-md-left text-center">
                  <div class="box d-flex align-items-center mx-md-0 mx-auto">
                    <img
                      src="../../../public/assets/img/icons/fast-delivery.png"
                      alt=""
                      class="img-fluid mx-auto"
                      width="70%"
                    />
                  </div>
                  <h6 class="mt-3">Fast Delivery</h6>
                  <p class="mb-5">
                    Satisfy your cravings! When hunger strikes, there's no time
                    to waste. Place your order and receive it in minutes.
                  </p>
                  <div class="box d-flex align-items-center mx-md-0 mx-auto">
                    <img
                      src="../../../public/assets/img/icons/meal-plan.png"
                      alt=""
                      class="img-fluid mx-auto"
                      width="70%"
                    />
                  </div>
                  <h6 class="mt-3">Schedule your meal</h6>
                  <p>
                    Eating well has never been easier! Schedule and create your
                    meals in advance without having to worry about your next
                    meal.
                  </p>
                </div>
                <div class="col-md-6 text-md-left text-center">
                  <div class="box d-flex align-items-center mx-md-0 mx-auto">
                    <img
                      src="../../../public/assets/img/icons/24-hours.png"
                      alt=""
                      class="img-fluid mx-auto"
                      width="70%"
                    />
                  </div>
                  <h6 class="mt-3 h6">24/7 Support</h6>
                  <p class="mb-5">
                    Our support team is constantly on standby and always at your
                    service to assist with your order, and listen to your query
                    or feedback.
                  </p>
                  <div class="box d-flex align-items-center mx-md-0 mx-auto">
                    <img
                      src="../../../public/assets/img/icons/time.png"
                      alt=""
                      class="img-fluid mx-auto"
                      width="70%"
                    />
                  </div>
                  <h6 class="mt-3">Meals from Top Restaurants</h6>
                  <p>
                    Best meals at your fingertip! Enjoy top meals from your
                    favourite Restaurants around.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="third-page d-md-block d-none py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="how-it-works py-5">
              <div class="container">
                <div class="pl-4">
                  <h1 style="font-size: 30px">How it Works</h1>
                  <div class="row pt-5">
                    <div class="col-md-12">
                      <h2 style="font-size: 18px">
                        <span class="pr-2" style="color: #e23744">01</span>
                        Choose Your Meals
                      </h2>
                      <p class="mb-5">
                        Select your meal for the day Breakfast, <br />
                        Lunch, or dinner.
                      </p>
                    </div>
                    <div class="col-md-12">
                      <h2 style="font-size: 18px">
                        <span class="pr-2" style="color: #e23744">02</span> Pay
                        For The Order
                      </h2>
                      <p class="mb-5">
                        Your order will be delivered to you in no time
                      </p>
                    </div>
                    <div class="col-md-12">
                      <h2 style="font-size: 18px">
                        <span class="pr-2" style="color: #e23744">03</span>
                        Enjoy your meal
                      </h2>
                      <p class="mb-5">
                        Relax and enjoy your meal anywhere you are
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-5 d-md-block d-none"
            style="position: absolute; right: 290px"
          >
            <div class="order-complete">
              <img
                src="../../../public/assets/img/Howitworks.jpg"
                alt=""
                style="max-width: 100% !important; height: 500px !important"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="third-page d-md-none d-block py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="how-it-works py-5">
              <div class="container pt-5">
                <div class="pt-5">
                  <h1 class="text-center" style="font-size: 30px">
                    How it Works
                  </h1>
                  <div class="row pt-5">
                    <div class="col-md-12">
                      <h2 class="text-center" style="font-size: 18px">
                        <span
                          class="pr-2 d-block"
                          style="
                            color: #e23744;
                            font-size: 30px;
                            padding-bottom: 10px;
                          "
                          >01</span
                        >
                        Choose Your Meals
                      </h2>
                      <p class="mb-5 text-center">
                          Select your meal for the day Breakfast, <br />
                        Lunch, or dinner.
                      </p>
                    </div>
                    <div class="col-md-12">
                      <h2 class="text-center" style="font-size: 18px">
                        <span
                          class="pr-2 d-block"
                          style="
                            color: #e23744;
                            font-size: 30px;
                            padding-bottom: 10px;
                          "
                          >02</span
                        >
                       Pay
                        For The Order
                      </h2>
                      <p class="text-center mb-5">
                        Your order will be delivered to you in no time
                      </p>
                    </div>
                    <div class="col-md-12">
                      <h2 class="text-center" style="font-size: 18px">
                        <span
                          class="d-block pr-2"
                          style="
                            color: #e23744;
                            font-size: 30px;
                            padding-bottom: 10px;
                          "
                          >03</span
                        >
                        Enjoy your meal
                      </h2>
                      <p class="text-center mb-5">
                            Relax and enjoy your meal anywhere you are
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-5 d-md-block d-none"
            style="position: absolute; right: 290px; top: 1392px"
          >
            <div class="order-complete">
              <img
                src="../../../public/assets/img/Howitworks.jpg"
                alt=""
                style="max-width: 100% !important; height: 500px !important"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Subscription or packages section -->
   <div class="container py-5" id="subscribe">
      <div>
        <h3 class="text-center">Choose your right plan!</h3>
        <p class="text-center plan-title">Select from best plans, ensuring a perfect match. Need more or less? Customize your subscription for a seamless fit</p>
      </div>

       <div class="row mt-4" v-if="loading">
            <div
              class="col-md-4 mb-3"
              v-for="n in 3"
              :key="n"
          
            >
         <div class="bg-white shadow-sm rounded p-4">
                <p class="h6 mb-2">
                  <VueSkeletonLoader
                    type="rect"
                    rounded
                    :width="300"
                    :height="10"
                    animation="fade"
                    class="loader-rect"
                    />
                  </p>
                  <p class="mb-4">
                  <VueSkeletonLoader
                      type="rect"
                      rounded
                      :width="300"
                      :height="10"
                      animation="fade"
                      class="loader-rect"
                      />
                  </p>
            
                <h3 class="">
                  <VueSkeletonLoader
                      type="rect"
                      rounded
                      :height="10"
                      animation="fade"
                      class="loader-rect"
                      />
                </h3>
                
                <hr />
              <!-- features/benefits -->
              <div class="mt-4 mb-1"
                
              >
                  <p>
                  <VueSkeletonLoader
                      type="rect"
                      rounded
                      :width="300"
                      :height="10"
                      animation="fade"
                      class="loader-rect"
                      />  
                  </p>
                  <p>
                  <VueSkeletonLoader
                      type="rect"
                      rounded
                      :width="300"
                      :height="10"
                      animation="fade"
                      class="loader-rect"
                      />  
                  </p>
                  <p>
                  <VueSkeletonLoader
                      type="rect"
                      rounded
                      :height="10"
                      animation="fade"
                      class="loader-rect"
                      />  
                  </p>
              </div>
              
            </div>
          </div>
      </div>
      
        <div class="row mt-4"  v-else>
          <div
          class="col-md-4 mb-3"
            v-for="(or, i) in plans"
            :key="i" 
          >
              <div class="bg-white shadow-sm rounded p-4">
              <p class="h6 mb-4"><span class="plan-badge">{{or.name}}</span></p>
                <p class="mb-4">
                  {{or.description}}
                </p>
            
                <h3 class="">₦{{numberWithCommas(or.price)}}<small class="">/month</small></h3>
                
                <hr />
              <!-- features/benefits -->
              <div class="mt-4 mb-1"
                v-for="(s, i) in or.benefits"
                  :key="i"
              >
                  <p>
                    <span class="feather-check text-primary"></span>
                    <span class="ml-2">{{s.benefit}}</span>
                  </p>
              </div>
                

              <hr />
              <div class="mt-2">
                <button @click="handleProPlan(or)" class="btn btn-getstarted">GET STARTED</button>
              </div>

            </div>
          </div>
        </div>

      

   </div>

    <footer class="section-footer bg-dark">
      <div class="container">
        <section class="footer-top padding-y py-5">
          <div class="row">
            <aside class="col-md-4 footer-about">
              <article class="d-flex pb-3">
                <div>
                  <img
                    alt="#"
                    src="../../../public/assets/img/logo.png"
                    class="logo-footer mr-3"
                  />
                </div>
                <div>
                  <h6 class="title text-white">About Us</h6>
                  <p class="text-muted">
                    The Meal Technology Company is the operating company for My
                    Meal Center, a food booking and scheduling app whose aim is
                    to provide individuals with an organized meal plan as well
                    as make it easier to access good and affordable meals,
                    especially at work
                  </p>
                  <div class="d-flex align-items-center">
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Facebook"
                      target="_blank"
                      href="#"
                      ><i class="feather-facebook"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Instagram"
                      target="_blank"
                      href="#"
                      ><i class="feather-instagram"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Youtube"
                      target="_blank"
                      href="#"
                      ><i class="feather-youtube"></i
                    ></a>
                    <a
                      class="btn btn-icon btn-outline-light mr-1 btn-sm"
                      title="Twitter"
                      target="_blank"
                      href="#"
                      ><i class="feather-twitter"></i
                    ></a>
                  </div>
                </div>
              </article>
            </aside>
            <aside class="col-sm-3 col-6 mb-3 col-md-2 text-white">
              <h6 class="title">Company</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/about-us" class="text-muted"
                    >About Us</router-link
                  >
                </li>
                <li>
                  <a href="#" class="text-muted">Careers</a>
                </li>
                <li>
                  <router-link to="/help" class="text-muted"
                    >Help & Support</router-link
                  >
                </li>
                <li>
                  <router-link to="/help" class="text-muted"
                    >Contact Us</router-link
                  >
                </li>
              </ul>
            </aside>
            <aside class="col-sm-3 col-6 mb-3 col-md-2 text-white">
              <h6 class="title">Services</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link href="/home#categories" class="text-muted"
                    >Categories</router-link
                  >
                </li>
                <li>
                  <router-link to="/trending" class="text-muted"
                    >Trending Meals</router-link
                  >
                </li>
                <li>
                  <router-link to="/recommended" class="text-muted"
                    >Recommended Meals</router-link
                  >
                </li>
                <!-- <li>
                  <a href="privacy.html" class="text-muted">Latest Meals</a>
                </li> -->
              </ul>
            </aside>
            <aside class="col-sm-3 col-6 col-md-2 text-white">
              <h6 class="title">For users</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/sign-in" class="text-muted">
                    User Login
                  </router-link>
                </li>
                <li>
                  <router-link to="/sign-up" class="text-muted">
                    User register
                  </router-link>
                </li>
                <li>
                  <a href="#" class="text-muted"> Forgot Password </a>
                </li>
                <li>
                  <a href="#" class="text-muted"> Account Setting </a>
                </li>
              </ul>
            </aside>
            <aside class="col-sm-3 col-6 col-md-2 text-white">
              <h6 class="title">More Links</h6>
              <ul class="list-unstyled hov_footer">
                <li>
                  <router-link to="/orders" class="text-muted">
                    My Orders
                  </router-link>
                </li>
                <li>
                  <a href="#" class="text-muted"> Reviews </a>
                </li>
                <li>
                  <router-link to="/privacy-policy" class="text-muted">
                    Privacy Policy
                  </router-link>
                </li>
                <li>
                  <router-link to="/terms" class="text-muted">
                    Terms of use
                  </router-link>
                </li>
              </ul>
            </aside>
          </div>
          <!-- row.// -->
        </section>
      </div>
      <!-- //container -->
      <section class="footer-copyright border-top py-4 bg-light">
        <div class="container d-flex flex-column flex-md-row align-items-center">
          <p class="mb-0">© 2020 My meal Center, All rights reserved</p>
          <p class="text-muted mt-3 mt-md-0 mb-0 ml-md-auto d-flex align-items-center">
            <a href="#" class="d-block"
              ><img
                alt="#"
                src="../../../public/assets/img/appstore.png"
                height="40"
            /></a>
            <a href="#" class="d-block ml-3"
              ><img
                alt="#"
                src="../../../public/assets/img/playmarket.png"
                height="40"
            /></a>
          </p>
        </div>
      </section>
    </footer>
  </div>
</template>
<style scoped>
@font-face {
  font-family: Plus Jakarta Display;
  src: url(../../../public/assets/fonts/PlusJakartaSans-Regular.481beba5.ttf);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Plus Jakarta Display;
  src: url(../../../public/assets/fonts/PlusJakartaSans-Medium.e65e8d41.ttf);
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Plus Jakarta Display;
  src: url(../../../public/assets/fonts/PlusJakartaSans-SemiBold.d43054d0.ttf);
  font-style: semi-bold;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Plus Jakarta Display;
  src: url(../../../public/assets/fonts/PlusJakartaSans-ExtraBold.e09cfb09.ttf);
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}
h2 {
  font-family: Plus Jakarta Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue !important;
  font-weight: 700;
}
button:hover {
  background-color: #f8c626 !important;
  border: 1px solid #f8c626;
  transition: 1s;
}
.typed-text {
  font-family: Plus Jakarta Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}
h1 {
  font-family: Plus Jakarta Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}
.just-relax {
  font-size: 30px !important;
  line-height: 50px;
  font-weight: 700;
}
.just-relax2 {
  font-size: 20px !important;
  /* line-height: 50px; */
  font-weight: 700;
  text-align: center;
}
.cursor {
  display: inline-block;
  width: 3px;
  margin-left: 4px;
  background: #e23744;
}

.cursor.blink {
  animation: blink 0.8s ease-in-out infinite;
}

@keyframes blink {
  0%,
  100% {
    background: #e23744;
  }
  40%,
  50% {
    background: transparent;
  }
}
.change {
  width: 30% !important;
  height: 55px;
  border-radius: 30px;
  border-right: none;
}
.change:focus{
  outline: none !important;
  box-shadow: none;
  border: 1px solid #ced4da;
}
/* .input-group-append {} */
.mob-inp input {
  width: 80% !important;
  height: 55px;
  border-radius: 30px;
}
.third-page {
  background: #fff5f5;
}
.search-btn {
  color: white;
  border-radius: 30px;
  height: 45px;
  position: absolute;
  top: 240px;
  right: 450px;
  padding-left: 30px;
  padding-right: 30px;
}
.searcho {
  color: white;
  border-radius: 30px;
  height: 55px;

  padding-left: 30px;
  padding-right: 30px;
}
.order-now {
  color: white;
  border-radius: 30px;
  height: 45px;
  padding-left: 30px;
  padding-right: 30px;
}
.register-now {
  color: black;
  border-radius: 30px;
  border: 1px solid #e23744;
  height: 45px;
  background: transparent !important;
  padding-left: 30px;
  padding-right: 30px;
}

.second-page .box {
  background: linear-gradient(
    99.04deg,
    #e23744 11.03%,
    #e23744 79.74%,
    #f1f1f1 115.28%,
    rgba(236, 238, 232, 0) 139.01%
  );
  border: none;
  width: 62px;
  height: 60px;
  border-radius: 8px !important;
}
.how-it-works {
  background: #ffdcdc;
  border-radius: 10px;
}
.second-page h6 {
  font-family: Plus Jakarta Display, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
  font-weight: 700 !important;
}
.second-page .iamgo {
  width: 100%;
}
.second-page .iamgo {
  width: 95%;
  margin-top: -92px;
  margin-left: -20px;
}

.btn-getstarted{
  color: #e23744;
  border-color: #e23744;
  width: 100%;
  padding: 0.75rem 0.75rem;
}

.btn-getstarted:hover{
    color: #fff;
      border-color: #e23744;
    background: #e23744  !important;
}

.btn-outline-primary:hover {
    color: #fff;
    background: #d92662;
    background: -moz-linear-gradient(-45deg,#d92662 0%,#e23744 100%);
    background: -webkit-linear-gradient(-45deg,#d92662 0%,#e23744 100%);
    background: linear-gradient(135deg,#d92662 0%,#e23744 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d92662',endColorstr='#e23744',GradientType=1);
    border-color: #e23744
}

.plan-title{
  max-width: 450px;
  margin: auto;
}

.plan-badge{
    color: white;
    background: #e23744;
    border-radius: 6px;
    padding: 8px;
}

.fw-bold {
    font-weight: 600!important;
}

@media screen and (max-width: 414px) {
  .second-page img {
    margin-top: 0 !important;
  }
  .plan-title{
    max-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .second-page img {
    margin-top: 0 !important;
  }
  .desk-inp {
    display: none;
  }
}
.order-complete {
  /* position: absolute; */
  /* right: 300px; */
}
.order-complete img {
  border-radius: 30px;
}
.desk-inp {
  width: 50%;
  margin: auto;
}
.mob-inp {
  width: 100%;
  margin: auto;
}
</style>
<script>
import axios from "axios";
import VueSkeletonLoader from 'skeleton-loader-vue';
export default {
  name: 'Home',
  data(){
    return{
      searchQuery: '',
      loading: false,
      plans: []
    }
  },
  components: {
    VueSkeletonLoader
  },
 methods:{
      // get current location
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
           this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
     async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBNDEeq0RAFJG6Iai_gTHIOsNE5_0EnV_c"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          console.log(data.results[0].formatted_address);
          this.address = data.results[0].formatted_address;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    handleProPlan(plan){
      localStorage.setItem('plan', JSON.stringify(plan));
      this.$router.push('/plan-checkout')
    },
    numberWithCommas(x){ 
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    },
    handleEnterKey(){
      this.$router.push(`/search?query=${this.searchQuery}`)
    },
    getPlans() {
      this.loading = true
      this.$store.dispatch("getPlans")
        .then((res) => {
          this.loading = false
          this.plans = res.data.data;
        })
        .catch(()=>{
           this.loading = false
        });
    },
  },
  mounted() {

     // get present location
    this.locatorButtonPressed();

    this.getPlans()

    const typedText = document.querySelector(".typed-text");
    const cursor = document.querySelector(".cursor");

    const textArray = [
      "What Would You Like To Eat ?",
      "Find Something Delicious for you.",
    ];

    let textArrayIndex = 0;
    let charIndex = 0;

    const erase = () => {
      if (charIndex > 0) {
        cursor.classList.remove("blink");
        typedText.textContent = textArray[textArrayIndex].slice(
          0,
          charIndex - 1
        );
        charIndex--;
        setTimeout(erase, 80);
      } else {
        cursor.classList.add("blink");
        textArrayIndex++;
        if (textArrayIndex > textArray.length - 1) {
          textArrayIndex = 0;
        }
        setTimeout(type, 1000);
      }
    };

    const type = () => {
      if (charIndex <= textArray[textArrayIndex].length - 1) {
        cursor.classList.remove("blink");
        typedText.textContent += textArray[textArrayIndex].charAt(charIndex);
        charIndex++;
        setTimeout(type, 120);
      } else {
        cursor.classList.add("blink");
        setTimeout(erase, 1000);
      }
    };

    document.addEventListener("DOMContentLoaded", () => {
      type();
    });
  },

};
</script>
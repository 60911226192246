var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      this.$router.currentRoute.fullPath !== '/sign-in' &&
      this.$router.currentRoute.fullPath !== '/sign-up' &&
      this.$router.currentRoute.fullPath !== '/' &&
      this.$router.currentRoute.fullPath !== '/forgot-password' &&
      !this.$route.path.includes('/reset-password')
    )?_c('AppHeader'):_vm._e(),_c('router-view'),(
      this.$router.currentRoute.fullPath !== '/sign-in' &&
      this.$router.currentRoute.fullPath !== '/sign-up' &&
      this.$router.currentRoute.fullPath !== '/' &&
      this.$router.currentRoute.fullPath !== '/forgot-password' &&
      !this.$route.path.includes('/reset-password')
    )?_c('AppFooter'):_vm._e(),(
      this.$router.currentRoute.fullPath !== '/sign-in' &&
      this.$router.currentRoute.fullPath !== '/sign-up' &&
      this.$router.currentRoute.fullPath !== '/' &&
      this.$router.currentRoute.fullPath !== '/forgot-password' &&
      !this.$route.path.includes('/reset-password')
    )?_c('AppSidebar'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
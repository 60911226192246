<template>
  <div>
    <div class="osahan-signup login-page">
      <img src="../../../public/assets/img/Register.jpg" alt="" />

      <div
        class="d-flex align-items-center justify-content-center flex-column vh-100"
      >
        <div class="px-5 col-md-6 ml-auto">
          <div class="px-5 col-10 mx-auto">
            <router-link to="/" class="">
                <p class="text-right m-0">Go Back to Home</p>
              </router-link>
            <h2 class="text-dark my-0 pt-5">Register Now.</h2>
            <p class="text-50">Fill in your details</p>
            <form>
              <vue-element-loading
                :active="loading"
                color="#FF6700"
                text="Please wait"
                spinner="line-wave"
              />
              <div class="form-group">
                <label for="exampleInputFirstname1" class="text-dark"
                  >Firstname</label
                >
                <input
                  type="text"
                  placeholder="Enter Firstname"
                  class="form-control"
                  id="exampleInputFirstname1"
                  aria-describedby="firstnameHelp"
                  v-model="form.f_name"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputLastname1" class="text-dark"
                  >Lastname</label
                >
                <input
                  type="text"
                  placeholder="Enter Lastname"
                  class="form-control"
                  id="exampleInputLastname1"
                  aria-describedby="lastnameHelp"
                  v-model="form.l_name"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPhone1" class="text-dark">Phone</label>
                <input
                  type="tel"
                  placeholder="Enter Phone Number"
                  class="form-control"
                  id="exampleInputPhone1"
                  aria-describedby="PhoneHelp"
                  v-model="form.phone"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1" class="text-dark">Email</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="form.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="text-dark"
                  >Password</label
                >
                <input
                  type="password"
                  placeholder="Enter Password"
                  class="form-control"
                  id="exampleInputPassword1"
                  v-model="form.password"
                  autocomplete="new-password"
                />
              </div>
              <button
                class="btn btn-primary btn-lg btn-block"
                @click.prevent="register()"
              >
                SIGN UP
              </button>
              <!-- <div class="py-3">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <GoogleLogin
                      :params="params"
                      :onSuccess="onSuccess"
                      class="btn btn-facebook btn-block btn-lg"
                      id="google-signin-button"
                      style="background-color: #1877f6 !important"
                    >
                      <i class="feather-google"></i> Connect with Google
                    </GoogleLogin>
                  </div>
                </div>
              </div> -->
            </form>
          </div>
          <div class="new-acc d-flex align-items-center justify-content-center mt-3">
            <router-link to="/sign-in">
              <p class="text-center m-0">Already have an account? Sign in</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  height: 100vh;
}
</style>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import GoogleSignInButton from "vue-google-signin";
import GoogleLogin from "vue-google-login";
// process.env.BASE_URL
export default {
  data() {
    return {
      params: {
        client_id:
          "422754129701-ae893jl448netpli3g6jo15mchcgl00j.apps.googleusercontent.com",
      },
      form: {
        f_name: "",
        l_name: "",
         email: "",
         phone: "",
        password: "",
      },
      loading: false,
    };
  },
  components: {
    VueElementLoading,
    GoogleSignInButton,
    GoogleLogin,
  },
  methods: {
    ro() {},
    register() {
      if(this.form.f_name !== "" && this.form.l_name !== "" && this.form.phone !== "" && this.form.email !== "" && this.form.password !== ""){
      this.loading = true;
      this.form.password_confirmation = this.form.password;
       this.$store
        .dispatch("Register", this.form)
        .then((res) => {
          this.loading = false;
          this.$toast.success("Registration Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          // var data = {
          //   auth_token: res.data.token,
          //   // auth_user : res.data.data.user
          // };
          // localStorage.setItem("auth_info", JSON.stringify(data));
          this.$router.push("/sign-in");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status == 403) {
            console.log(2908);
            this.$toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            return;
          }
        });
      }
      else{
         this.$toast.error("All Fields Are Required", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    onSuccess(googleUser) {
      console.log(1);
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());
    },
  },
  mounted() {},
};
</script>
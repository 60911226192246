<template>
  <div>
    <div class="login-page vh-100">
      <img src="../../../public/assets/img/Signin.jpg" alt="" />
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="px-5 col-md-6 ml-auto">
          <div class="px-5 col-10 mx-auto">
            <h2 class="text-dark my-0">Reset Password</h2>
            <p class="text-50">
               Create a new unique password
            </p>
            <form class="mt-5 mb-4" @submit.prevent="reset">
              <vue-element-loading
                :active="loading"
                color="#FF6700"
                text="Please wait"
                spinner="line-wave"
              />
               <div class="form-group">
                <label for="exampleInputPassword" class="text-dark">Token</label>
                <input
                  type="text"
                  placeholder="Enter the reset token sent via your email"
                  class="form-control"
                  id="exampleInputToken"
                  aria-describedby="passwordToken"
                  v-model="form.token"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword" class="text-dark">New Password</label>
                <input
                  type="password"
                  placeholder="Enter new password"
                  class="form-control"
                  id="exampleInputPassword"
                  aria-describedby="newPassword"
                  v-model="form.password"
                  autocomplete="off"
                />
              </div>

                <div class="form-group">
                <label for="exampleInputPassword1" class="text-dark">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm your password"
                  class="form-control"
                  id="exampleInputPassword1"
                  aria-describedby="confirmPassword"
                  v-model="form.confirmpassword"
                  autocomplete="off"
                />
              </div>

              <button class="btn btn-primary btn-lg btn-block">Send</button>
            </form>

            <div class="d-flex align-items-center justify-content-center">
              <router-link to="/sign-in">
                <p class="text-center m-0">Remember password? Back to Login</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
export default {
  data() {
    return {
      form: {
        token: "",
        password: "",
        confirmpassword: ""
      },
      loading: false,
    };
  },
  components: {
    VueElementLoading,
  },
  methods: {
    reset() {
      if (this.form.token !== "" && this.form.password !== "" && this.form.confirmpassword !== "") {
        if(this.form.password === this.form.confirmpassword){
            this.loading = true;
            const email =  this.$route.query.email
            let info = {
                email: email,
                reset_token:  this.form.token,
                password: this.form.password,
                password_confirmation: this.form.confirmpassword
            }
            this.$store
          .dispatch("ResetPassword", info)
          .then((res) => {
            this.loading = false;
            this.$toast.success("Password changed successfully, You can now proceed to Login!", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });

             setTimeout(()=>{
              this.$router.push("/sign-in");
            },500)
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              let msg = error.response.data.errors[0].message
              this.$toast.error(`${msg}`, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              return;
            }
          }); 
        }
        else{
            this.$toast.error("Passwords do not match", {
              position: "top-right",
            })
        }
       
      } else {
        this.$toast.error("All Fields Are Required", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
  mounted() {

  },
};
</script>
<template>
  <div>
    <div class="container pt-4 pb-4" v-if="cartItems.length">
      <div class="row">
        <div class="col-md-5 mb-3">
          <div class="row">
            <div class="col-md-12" v-for="(ct, i) in cartItems" :key="i">
              <div class="card mb-3 py-2">
                <div class="container">
                  <div class="row">
                    <div class="col-md-3 col-4 pt-2 pb-2">
                      <img
                        :src="
                          'https://backend.seid.in/storage/product/' +
                          ct.product.image
                        "
                        alt=""
                        style="border-radius: 10px"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-md-9 col-8 pt-2">
                      <div class="row">
                        <div class="col-md-5">
                          <h2 class="mb-2" style="font-size: 15px">
                            {{ ct.product.name }}
                          </h2>
                          <div v-if="ct.addons.length">
                            <div class="row mt-2">
                              <div
                                class="col-md-12"
                                v-for="(ad, i) in ct.addons"
                                :key="i"
                              >
                                <div class="row">
                                  <div class="col-md-6 col-6">
                                    <p>
                                      {{ ad.addon }}
                                    </p>
                                  </div>
                                  <div class="col-md-6 col-6">₦ {{ ad.price }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h2
                            style="font-size: 16px; font-weight: bolder"
                            v-if="ct.addons.length"
                          >
                            ₦
                            {{
                              ct.quantity * ct.product.price +
                              sumAddons(ct.addons)
                            }}
                          </h2>
                          <h2
                            style="font-size: 16px; font-weight: bolder"
                            v-else
                          >
                            ₦
                            {{ ct.quantity * ct.product.price }}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-12 mb-2">
                      <div class="row">
                        <div class="col-md-3 col-5">
                          <div>
                            <div class="mt-4" @click="deleteCart(ct)">
                              <i class="bi bi-trash cart-delete"></i>
                              <i class="pl-1 cart-delete">REMOVE</i>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9 col-7">
                          <div class="mt-3">
                            <div class="float-right">
                              <button
                                class="dec-button mr-3"
                                @click="minus(ct)"
                                :disabled="ct.quantity === 1"
                              >
                                -
                              </button>
                              {{ ct.quantity }}
                              <button class="dec-button ml-3" @click="add(ct)">
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6">
          <div class="card pt-3 pb-3">
            <div class="container">
              <div class="input-group">
                <input
                  type="text"
                  name=""
                  class="form-control promo"
                  id=""
                  v-model="coupon"
                  placeholder="Enter Promo Code"
                />
                <div class="input-append">
                  <button :disabled="loading" class="btn btn-primary promo-btn" @click="applyCoupon">Apply</button>
                </div>
              </div>

              <div class="brakdown pt-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 col-6">
                        <h2 style="font-size: 16px">Items Price</h2>
                      </div>
                      <div class="col-md-6 col-6">
                        <h2 style="font-size: 16px" class="float-right">
                          ₦ {{ totalAmount }}
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 pt-2">
                    <div class="row">
                      <div class="col-md-6 col-6">
                        <h2 style="font-size: 16px" class="text-success"> Total Discount</h2>
                      </div>
                      <div class="col-md-6 col-6">
                        <h2 style="font-size: 16px" class="float-right">
                          ₦ {{discountAmount}}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-12 pt-2">
                    <div class="row">
                      <div class="col-md-6">
                        <h2
                          style="
                            font-size: 18px;
                            font-weight: bolder;
                            color: #ee3338;
                          "
                        >
                          Subtotal
                        </h2>
                      </div>
                      <div class="col-md-6">
                        <h2
                          style="
                            font-size: 18px;
                            font-weight: bolder;
                            color: #ee3338;
                          "
                          class="float-right"
                        >
                          ₦ {{ totalAmount - discountAmount }}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <button :disabled="loading" class="btn btn-primary w-100" @click="checkout()">
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="align-items-center text-center">
            <img
              src="../../../public/assets/img/empty-cart.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.promo {
  height: 40px;
  font-weight: bolder;
  border-radius: 10px 10px 10px 10px;
}
.promo-btn {
  height: 40px;
  font-weight: bolder;
  border-radius: 0px 10px 10px 0px;
}
.cart-delete {
  color: red;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
}
.dec-button {
  background-color: #e23744;
  border: 1px solid #e23744;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dec-button:disabled{
  opacity: 0.5;
}
</style>
<script>
export default {
  data() {
    return {
      showModal: false,
      quantity: 1,
      cartItems: {},
      totalAmount: "",
      coupon: "",
      loading: false,
      discountAmount: 0,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    checkout() {
      localStorage.setItem("discountAmount", this.discountAmount);
      this.$router.push("/checkout");
    },
    closeModal() {
      this.showModal = false;
    },
    add(qua) {
      qua.quantity++;
      localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
      this.displayCart();
    },
    minus(qua) {
      if (qua.quantity !== 1) {
        qua.quantity--;
        localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
        this.displayCart();
      }
    },
    sumAddons(add_ons) {
      let totalValue = 0;

      for (const add_on of add_ons) {
        const quantity = add_on.quantity || 1;
        const price = add_on.price || 0;
        totalValue += quantity * price;
      }

      return totalValue;
    },
       applyCoupon(){
         this.loading = true;
        const zoneValue = JSON.parse(localStorage.getItem("zone"));
       let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      let res = {
        coupon: this.coupon,
        restaurant_id: cartItems.length ? cartItems[0].product.restaurant_id: "",
        zoneId: zoneValue.id
      }
      this.$store.dispatch("applyCoupon",res)
      .then((res)=>{
           this.loading = false
            this.$toast.success("Coupon code applied!", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          this.discountAmount = res.discount
          localStorage.setItem("couponCode", this.coupon);
          
      })
      .catch((error)=>{
           this.loading = false
        if(error.response.status === 404){
            this.$toast.error("There is no coupon available", {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
         }
         else{
            this.$toast.error(error.response.data.errors[0].message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });  
         }
      })
    },
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
      this.calculateCartTotal(cartItems);
    },
    calculateCartTotal(cartItems) {
      let totalAmount = 0;

      // Parse the cartItems JSON string into an array
      const items = cartItems;
      
      // Loop through each item in the cart
      items.forEach((item) => {
        const { quantity, product } = item;
        const { price } = product;
        var extras = item.addons
        const extrasTotalPrice = extras.reduce((total, extra) => total + (extra.price * extra.quantity), 0);
      
        // Multiply the price by the quantity and add it to the total amount
        totalAmount += price * quantity + extrasTotalPrice;
      });

      this.totalAmount = totalAmount;
    },
    deleteCart(item) {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const updatedCart = cartItems.filter(
        (cartItem) => cartItem.product.id !== item.product.id
      );
      this.$toast.success("Item Removed", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      localStorage.setItem("cartItems", JSON.stringify(updatedCart));
      this.displayCart();
    },
  },
  mounted() {
    this.displayCart();
  },
};
</script>
<template>
  <div>
    <div class="py-4 osahan-main-body">
      <div class="container">
        <div class="row">
          <div class="col-md-4 d-none d-md-block">
            <ProfileSidebar />
          </div>
          <div class="tab-content col-md-8" id="myTabContent">
             <h5 class="mb-3 d-md-none block">Order Info</h5>
              <div v-if="loading">
                <div class="">
                    <div
                          v-for="n in 2"
                          class="tab-pane fade show active"
                          :key="n"
                    >
                      <div class="p-3 rounded shadow-sm bg-white mb-3" >
                        <div style="display: flex; gap:10px; align-items: center;">
                          <div>
                              <VueSkeletonLoader
                              type="circle"
                              rounded
                              :width="74"
                              :height="74"
                              animation="fade"
                            /> 
                          </div>
                          <div>
                            <VueSkeletonLoader
                                type="rect"
                                rounded
                                  :width="600"
                                :height="10"
                                animation="fade"
                                class="loader-rect"
                                />
                                <VueSkeletonLoader
                                type="rect"
                                rounded
                                :width="600"
                                :height="10"
                                animation="fade"
                                  class="loader-rect mt-2"
                                />
                                  <VueSkeletonLoader
                                type="rect"
                                rounded
                                :height="10"
                                  :width="600"
                                animation="fade"
                                class="mt-2 loader-rect"
                                />
                          </div>
                        </div>
                        <div class="d-flex border-bottom pb-3"></div>
                          <VueSkeletonLoader
                                type="rect"
                                rounded
                                :height="10"
                                  :width="600"
                                animation="fade"
                                class="mt-3 loader-rect"
                              />
                      </div>

                      
                  
                    </div>
                </div>     
              </div>

              <div
              class="tab-pane fade show active"
              id="completed"
              role="tabpanel"
              aria-labelledby="completed-tab"
              v-for="(or, i) in orderInfo"
              :key="i"
              v-else
            >
              <div class="order-body">
                <div class="pb-3">
                  <div class="p-3 rounded shadow-sm bg-white">
                    <div class="d-flex border-bottom pb-3">
                      <div class="text-muted mr-3">
                        <img
                          alt="#"
                         :src="
                            'https://backend.seid.in/storage/product/' +
                            or.food_details.image
                          "
                          class="img-fluid order_img rounded"
                        />
                      </div>
                      <div>
                        <p class="mb-0 fw-bold">
                          <a href="restaurant.html" class="text-dark"
                            >{{or.food_details.restaurant_name}} | {{
                          or.food_details.min_delivery_time + "-" + or.food_details.max_delivery_time
                        }}mins</a
                          >
                        </p>
                        <p class="mb-0">{{or.food_details.description}}</p>
                        <p class="mb-0">ORDER REF #{{ or.order_id }}</p>
                        
                        
                      </div>
                      <div class="ml-auto">
                    
                       
                           <p class="small fw-bold text-center">
                              <i class="feather-clock"></i>
                              {{ formatDate(or.created_at) }}
                            </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center pt-3">
                      <div class="small">
                      <p class="text- fw-bold mb-0">{{or.food_details.name}} x {{or.quantity}}</p>
                      </div>
                      <div class="text-muted m-0 ml-auto mr-3 small"><br>
                      <span class="text-dark fw-bold">₦{{or.price}}</span>
                      </div>      
                    </div>

                    <div
                        v-for="(s, i) in or.add_ons"
                        :key="i"
                     class="d-flex align-items-center">
                      <div class="small">
                      <p class="text- fw-bold mb-0">{{s.name}} x {{s.quantity}}</p>
                      </div>
                      <div class="text-muted m-0 ml-auto mr-3 small"><br>
                      <span class="text-dark fw-bold">₦{{s.price}}</span>
                      </div>      
                    </div>
                
                  </div>
                </div>
              </div>

                <section class="bg-white osahan-main-body rounded shadow-sm overflow-hidden">
                <div class="container p-0">
                <div class="row">
                <div class="col-lg-12">
                <div class="osahan-status">

               
               

                <div class="p-3 border-bottom bg-white">
                <h6 class="fw-bold">Delivery Address</h6>
                <p class="m-0 small">{{or.delivery_address.address}}</p>
                </div>
               

                <div class="p-3 bg-white">
                <div class="d-flex align-items-center mb-2">
                <h6 class="fw-bold mb-1">Total Cost</h6>
                <h6 class="fw-bold ml-auto mb-1">₦{{totalAmount}}</h6>
                </div>
                <p class="m-0 small text-muted">You can check your order detail here,<br>Thank you for order.</p>
                </div>
                </div>
                </div>
                </div>
                </div>
            </section> 

            </div>

         


          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileSidebar from "../Menu/ProfileSidebar.vue";
import axios from "axios";
import VueSkeletonLoader from 'skeleton-loader-vue';
export default {
  data() {
    return {
      orderInfo: [],
      totalAmount: 0,
      user: null,
      partOrder: {},
      responseBod: {},
      loading: false
    };
  },
  components: {
    ProfileSidebar,
    axios,
    VueSkeletonLoader
  },
  methods: {
    getOrder() {
      this.loading = true
      let id = this.$route.query.ref
      this.$store.dispatch("getRunningOrderByID",id)
        .then((res) => {
          this.loading = false
          console.log(res)
          this.orderInfo = res.details;
          this.totalAmount = res.total_amount;
        })
        .catch((error)=>{
           this.loading = false
          console.log(error)
        });
    },
     capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
    },

    formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.loading=true
    this.user = this.authUser();
    this.getOrder();
  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  .loader-rect{
    width: 200px !important;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../pages/Home/Home.vue'
import Cart from '../pages/Home/Cart.vue'
import Checkout from '../pages/Home/Checkout.vue'
import PlanCheckout from '../pages/Home/PlanCheckout.vue'
import Privacy from '../pages/Home/Privacy.vue'
import About from '../pages/Home/AboutUs.vue'
import Help from '../pages/Home/Help.vue'
import Terms from '../pages/Home/TermsOfUse.vue'
import Web from '../pages/Home/Web.vue'
import AppLogin from '../pages/Auth/AppLogin.vue'
import AppRegister from '../pages/Auth/AppRegister.vue'
import ForgotPassword from '../pages/Auth/ForgotPassword.vue'
import ResetPassword from '../pages/Auth/ResetPassword.vue'
import Menu from '../pages/Menu/Index.vue'
import Category from '../pages/Home/Category.vue'
import Trending from '../pages/Home/Trending.vue'
import Recommended from '../pages/Home/Recomended.vue'
import Search from '../pages/Home/Search.vue'
import Profile from '../pages/Menu/Profile.vue'
import ChangePassword from '../pages/Menu/ChangePassword.vue'
import Orders from '../pages/Home/Orders.vue'
import ScheduledMeals from '../pages/Home/ScheduledMeals.vue'
import SubscriptionPlan from '../pages/Home/SubscribedPlans.vue'
import OrderSuccess from '../pages/Home/Success.vue'
import SubSuccess from '../pages/Home/SubSuccess.vue'
import OrderStatus from '../pages/Home/Status.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Web',
    component: Web,

  },
  {
    path: '/meals',
    name: 'Home',
    component: HomeView,
    
  },
  {
    path: '/sign-in',
    name: 'Login',
    component: AppLogin
  },
  {
    path: '/sign-up',
    name: 'Register',
    component: AppRegister
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,

  },
  {
    path: '/category/:category_id',
    name: 'Category',
    component: Category,

  },
  {
    path: '/trending',
    name: 'Trending',
    component: Trending,

  },
  {
    path: '/recommended',
    name: 'Recommended',
    component: Recommended,

  },
  {
    path: '/search',
    name: 'Search',
    component: Search,

  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/scheduled-meal',
    name: 'ScheduledMeals',
    component: ScheduledMeals,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/subscription-plan',
    name: 'Subscription',
    component: SubscriptionPlan,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/cart',
    name: 'Cart',
    component: Cart,

  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/plan-checkout',
    name: 'PlanCheckout',
    component: PlanCheckout
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,

  },
  {
    path: '/about-us',
    name: 'About',
    component: About,

  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,

  },
  {
    path: '/help',
    name: 'Help',
    component: Help,

  },
  {
    path: '/order-successful',
    name: 'OrderSuccess',
    component: OrderSuccess,
  },
  {
    path: '/subscription-successful',
    name: 'SubSuccess',
    component: SubSuccess,
  },
  {
    path: '/order-details',
    name: 'OrderStatus',
    component: OrderStatus,
  },
  // {
  //   path: '/about-us',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

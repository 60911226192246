import axios from "axios";
import { apiUrl } from "./config";


export const home ={
    state: {
        // Your initial state properties go here
       products: [],
       orders: [],
       order: [],
       subscriptions: [],
       plans: [],
       UserSubPlans: []
      },
      getters: {
        // Your getters go here
      },
      mutations: {
        // Your mutations go here
        products(state, data) {
            state.products = data.products;
          },
          orders(state, data){
            state.orders = data
          },
          order(state, data){
            state.order = data.details
          },
          Subscriptions(state, data){
            state.subscriptions = data
          },
          UserSubPlans(state, data){
            state.UserSubPlans = data
          },
          Plans(state, data){
            state.plans = data.data
          }
          
      },
      actions: {
        // Your actions go here
          // search products
          SearchProducts: ({ commit }, payload) => {
            const res = {
              name: payload.name,
            };
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}products/search?name=${payload.name}`,{
                  headers: {
                    zoneId: payload.zoneId === 1 ? "[1]" : "[2]"
                  }
                })
                .then(({ data, status }) => {
                  if (status === 200) {
                    console.log(data)
                    commit("products", data);
                    resolve(data);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
          // verify payments
          verifyPayment: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios
                .post(`${apiUrl}test-payment-verify`,{
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    console.log(data);
                    resolve(data);
                    commit()
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          // get running orders
          getRunningOrders: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}customer/order/running-orders?type=all &limit=0&offset=0`,{
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    commit("orders", data.orders)
                    resolve(data);
                 
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          // get order by ID
          getRunningOrderByID: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}customer/order/details?order_id=${payload}`,{
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    commit("order", data)
                    resolve(data);
                 
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          // apply coupon
          applyCoupon: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
              let coupon = payload.coupon
              let id  = payload.restaurant_id
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}coupon/apply?code=${coupon}&restaurant_id=${id}`,{
                  headers: {
                    Authorization:  `Bearer ${token}`,
                    zoneId: payload.zoneId === 1 ? "[1]" : "[2]"
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    console.log(data);
                    resolve(data);
                    commit()
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          // get subscription orders
          getSubOrders: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}customer/order/order-subscription-list?type=all &limit=0&offset=0`,{
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    commit("Subscriptions", data.orders)
                    resolve(data);
                 
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          getUserSubPlans: ({ commit }, payload) => {
            let token =
            localStorage.getItem("auth_info") != null
              ? JSON.parse(localStorage.getItem("auth_info")).auth_token
              : null;
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}customer/order/subscriptions`,{
                  headers: {
                    Authorization:  `Bearer ${token}`
                  }
                },payload)
                .then(({ data, status }) => {
                  if (status === 200) {
                    commit("UserSubPlans", data.suscriptionPlans)
                    resolve(data);
                 
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

          // get plans
          getPlans: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
              axios
                .get(`${apiUrl}subscription/plan-with-benefits`)
                .then(({ data, status }) => {
                  if (status === 200) {
                    commit("Plans", data.data)
                    resolve(data);
                 
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },

      },  
}
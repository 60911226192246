<template>
  <div>
    <div
      class="py-5 osahan-coming-soon d-flex justify-content-center align-items-center"
    >
      <div class="col-md-6">
        <div class="text-center pb-3">
          <h1 class="fw-bold">
            {{ user.f_name }}, Your order has been successful
          </h1>
          <p>
            Check your order status in
            <router-link
              to="/orders"
              class="fw-bold text-decoration-none text-primary"
              >My Orders</router-link
            >
            about next steps information.
          </p>
        </div>

        <div class="bg-white rounded text-center p-4 shadow-sm">
          <h1 class="display-1 mb-4">🎉</h1>
          <h6 class="fw-bold mb-2">Preparing your order</h6>
          <p class="small text-muted">
            Your order will be prepared and will come soon
          </p>
          <!-- <a
            href="status_onprocess.html"
            class="btn rounded btn-primary btn-lg w-100"
            >Track My Order</a
          > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus.js";
export default {
  data() {
    return {
      user: null,
      cartItems: {},
      zones: {},
      selectedZone: null,
    };
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    getZones() {
      this.$api.get(this.baseUrl("zone/list")).then((res) => {
        this.zones = res.data;
        this.selectedZone = res.data[0];
      });
    },
  },
  mounted() {
    this.user = this.authUser();
    this.getZones();
    this.displayCart();
    EventBus.$on("itemAddedToCart", () => {
      // Update cart item count
      this.displayCart();
    });
  },
  beforeDestroy() {
    // Clean up event listener when the component is destroyed
    EventBus.$off("itemAddedToCart");
  },
};
</script>
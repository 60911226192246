<template>
  <div>
    <div class="osahan-profile">
      <div class="d-none">
        <div
          class="border-bottom p-3 d-flex align-items-center"
          style="background: #e23744"
        >
          <a class="toggle togglew toggle-2" href="#"><span></span></a>
          <h4 class="font-weight-bold m-0 text-white">Menu</h4>
        </div>
      </div>
      <!-- profile -->
      <div class="container position-relative">
        <div class="py-5 osahan-profile row">
          <div class="col-md-4 mb-3 d-none d-md-block">
            <ProfileSidebar />
          </div>
          <div class="col-md-8 mb-3">
            <div class="rounded shadow-sm p-4 bg-white">
              <h5 class="mb-4">Change Password</h5>
              <div id="edit_profile">
                <div>
                  <form @submit.prevent="ro">
                       <vue-element-loading
                          :active="loading"
                          color="#FF6700"
                          text="Please wait"
                          spinner="line-wave"
                        />

                    <div class="form-group">
                      <label for="exampleInputName1">New Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputName1d"
                        v-model="newpassword"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputName1">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputName1"
                        v-model="confirmpassword"
                      />
                    </div>
                   
                    <div class="text-center">
                      <button
                      :disabled="loading"
                      @click="UpdatePassword"
                       type="submit" class="btn btn-primary btn-block">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileSidebar from "./ProfileSidebar.vue";
import VueElementLoading from "vue-element-loading";
export default {
  data() {
    return {
      user: null,
      loading: false,
      cartItems: {},
      zones: {},
      selectedZone: null,
      newpassword: "",
      confirmpassword: ""
    };
  },
  components: {
    ProfileSidebar,
    VueElementLoading,
  },
  methods: {
    displayCart() {
      // Retrieve the cart items from localStorage
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      this.cartItems = cartItems;
    },
    UpdatePassword(){
    if (this.newpassword !== "" && this.confirmpassword !== "") {

        if(this.newpassword === this.confirmpassword){
            this.loading = true
            let res = {
                password: this.newpassword,
                password_confirmation: this.confirmpassword,
                f_name: this.user.f_name,
                l_name: this.user.l_name,
                phone: this.user.phone,
                email: this.user.email
             }
                this.$store.dispatch("ChangePassword",res)
                .then(()=>{
                    
                    this.loading = false
                    this.$toast.success("Password successfully updated!", {
                        position: "top-right",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                        });

                    setTimeout(()=>{
                        this.logoutUser()
                    },500)
                })
                .catch(()=>{
                        this.loading = false
                    this.$toast.error("Oops, an error occured updating your password", {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
         })
        }
        else{
                this.$toast.error("Passwords do not match", {
                position: "top-right",
                })
            }
     } else {
        this.$toast.error("All Fields Are Required", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    getZones() {
      this.$api.get(this.baseUrl("zone/list")).then((res) => {
        this.zones = res.data;
        this.selectedZone = res.data[0];
      });
    },
  },
  mounted() {
    this.user = this.authUser();
    this.getZones();
    this.displayCart();
  },
};
</script>